import React, { useEffect } from "react";
import { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { FaCircleNotch } from "react-icons/fa6";
import EditTestcases from "./components/EditTestCases";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Difficulty from "../DomainPages/components/Difficulty"
import Points from "./components/Points";
import LanguageId from "./LanguageId"
import { useToast } from "@chakra-ui/react";
import Editor from "./components/Editor"
import InitialCode from "./components/InitialCode"


export default function Editproblem() {
  const [formData, setFormData] = useState(

    {
      parentDomainId: '',
      properties: { subdomain: '', difficultyLevel: '', pointsToBeEarned: '' },
      problemId: '',
      problemName: '',
      problemHeading: '',
      description: '',
      constraints: '',
      sampleInput: '',
      sampleOutput: '',
      langId: '',
      predefinedCode:{} ,
      testCases: [{ testId: '', defaultInput: '', expectedOutput: '' }], // Initial value for difficulty
    }
  );


  const [domains, setDomains] = useState([])
  const [syllabus, setSyllabus] = useState('')
  const [syllabusData, setSyllabusData] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const toast=useToast()
  const Base_Url=process.env.REACT_APP_LMS_URL
  const { problem } = useParams()

  async function getProblem() {
    try {
      const response = await axios.get(`${Base_Url}/${problem}/getproblem`);
      // console.log(response.data.problem);
      setFormData(response.data.problem)
      setSyllabus(response.data.problem.parentDomainId)

    }
    catch (error) {
      console.log(error)
    }

  }
  async function fetchDomain() {
    try {
      const response = await axios.get(`${Base_Url}/alldomains`);


      setDomains(response.data.domains)

    }
    catch (error) {
      console.error("error fetching domain")
    }

  }


  async function fetchSyllabus(syllabus) {
    // console.log(syllabus);
    try {
      const response = await axios.get(`${Base_Url}/${syllabus}/syllabus`);

      setSyllabusData(response.data.syllabus)

    }
    catch (error) {
      console.error("error fetching syllabus")
    }
  }

  useEffect(() => {
    getProblem()
    fetchDomain()

  }, [])

  useEffect(() => {
    if (formData.parentDomainId) {
      fetchSyllabus(formData.parentDomainId);
    }
  }, [formData]);



  const handleCodeChange = (lang, code) => {
    setFormData((prevData) => ({
      ...prevData,
      predefinedCode: {
        ...prevData.predefinedCode,
        [lang]: code,
      },
    }));
  };

  const handleTextarea = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTextEditor = (name, value) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  }

  const handleDropdown = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const langDropdown = (e) => {
    // const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      langId: e.target.value,
    }))
    setSyllabus(prevData => ({
      ...prevData,
      langId: e.target.value,
    }))
  }



  const difficultyValues = {
    beginner: 3,
    intermediate: 5,
    advanced: 8,
    master: 12,
  };

  const handlePropertyClick = (property, value) => {
    // console.log(`${value}: ${difficultyValues[value]}`);

    const pointsToBeEarned = difficultyValues[value]?.toString() ?? '0';

    setFormData((prevData) => ({
      ...prevData,
      properties: {
        ...prevData.properties,
        [property]: value,
        pointsToBeEarned,
      },
    }));
  };



  const navigate = useNavigate()



  // const handleTestCaseUpdate = (updatedTestCase) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     testCases: updatedTestCase,
  //   }));
  //   console.log(formData.testCases)
  // };
  const handleTestCaseUpdate = (updatedTestCase) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData, testCases: updatedTestCase };
      // console.log(updatedData.testCases); // Log the updated test cases
      return updatedData;
    });
  };

  const handleTestCaseDelete = (updatedTestCase) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData, testCases: updatedTestCase };
      // console.log(updatedData.testCases); // Log the updated test cases
      return updatedData;
    });
  };
  

  const handleSubmit = async(e) => {
    e.preventDefault();
    // console.log(formData)
    try {
      const response =await axios.put(`${Base_Url}/${problem}/updateproblem`, formData);
      toast({
        title: "Problem Edited Successfully",
        status: "success",
        position: "top",
        isClosable: true,
      });
      // console.log('Update successful:', response.data);
    } catch (error) {
      console.error('Error updating data:', error);
      toast({
        title: "An Error Occoured",
        status: "error",
        position: "top",
        isClosable: true,
      });
    }

    navigate("/")



  };

  function dateConverter(date) {
    // const [year, month, day] = date.split('-');
    // const formattedDate = `${day}-${month}-${year}`;
    if (!date) {
      return null
    }
    const apiDate = date.slice(0, 10);
    const [year, month, day] = apiDate.split('-')
    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;

  }

  function checkAMorPM(timeString) {
    if (!timeString) {
      return null;
    }
    const hour = parseInt(timeString.substring(11, 13), 10);
    if (hour >= 12) {
      return timeString.slice(11, 16) + " " + "PM";
    } else {
      return timeString.slice(11, 16) + " " + "AM";
    }
  }

  const handleLoading = () => {

    if (!formData.parentDomainId || !formData.properties.subdomain || !formData.problemName || !formData.description) {
      
      return;
    }
  
    // Proceed with loading if all required fields are filled
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Show loading div for 2-3 seconds
  };

  return (
    <div className="h-full pl-16 pr-16 text-lg font-semibold bg-bgBlue w text-textBlue font-[raleway] ">
        {isLoading && (
       <div className="fixed inset-0 flex items-center justify-center bg-[rgba(255,255,255,0.5)] backdrop-blur-sm">
       <div className="flex flex-col items-center justify-center font-extrabold text-6xl">
         <div className="animate-spin">
           <FaCircleNotch />
         </div>
         <div className="m-10">
           LOADING<span className="smooth-loading">.....</span>
         </div>
       </div>
     </div>
     
      )}
      <div className="pt-8 text-3xl font-bold text-center">Edit Problem</div>
      <form onSubmit={handleSubmit}>
        <div className="flex justify-between m-7">
          <div>
            <div className="mt-16">
              <div>
                <div
                  name="parentDomainId"


                  className="py-3 p-4 bg-blue-200 rounded h-12 flex m-auto w-80"
                >

                  {formData.parentDomainId}


                </div>
              </div>
            </div>

            <div className="mt-16 m-7 ">
              <div>
                <div
                  name="subdomain"

                  className="bg-blue-200 rounded text-start h-11 w-80 p-2 font-semibold "

                >

                  {formData?.properties?.subdomain}
                </div>
              </div>
            </div>
          </div>
          <div >
            <div className="m-6 max-h-12 w-80">
              <div>
                <div className="m-10">
                  <div className="mt-6 "> Problem Name </div>
                  <div>
                    <input
                      name="problemName"
                      value={formData.problemName}
                      onChange={handleTextarea}
                      className="w-full pl-5 text-sm rounded-md outline-none resize-none h-11 bg-inputBg border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80"
                      placeholder="Enter Domain ID..."
                    ></input>
                  </div>
                  <div className="mt-10">
                <div className="">
                  <select
                    name="langId"
                    value={formData.langId}
                    onChange={(e) => { langDropdown(e)}}
                    // onClick={fetchSyllabus(formData.parentDomainId)}

                    className="py-3 bg-blue-200 rounded h-11 w-60"
                  >
                    <option className="py-2 bg-blue-100" value="">Language ID </option>

                    {Array.isArray(LanguageId) && LanguageId.map((language, index) => (
                      <option className="py-2 bg-blue-100 space-x-5" key={index} value={language.langId}>
                        <div className="flex justify-cente " >
                          <div >{language.langId}</div> ||
                          <div> v{language?.versionIndex}</div>  </div>
                      </option>
                    ))}
                  </select>
                </div>
              </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div>
          <div className="">
            <div className="text-2xl font-bold">Heading:</div>

            <div>
              <textarea
                name="problemHeading"
                value={formData.problemHeading}
                onChange={handleTextarea}
                className="rounded-md resize-none bg-inputBg outline-none w-full text-[#3D4E74]  p-5 pl-5 pt-5 text-lg custom-scrollbar"
                placeholder="Enter heading here..."
              ></textarea>
            </div>
          </div>
          <div className="mt-14 ">
            <div className="text-2xl font-bold">Description :</div>
            <div>
              {/* <textarea
                name="description"
                value={formData.description}
                onChange={handleTextarea}
                className="rounded-md h-40 outline-none resize-none bg-inputBg w-full text-[#3D4E74]  p-5 pl-5 pt-5 text-lg custom-scrollbar"
                placeholder="Enter your Description here... "
              ></textarea> */}
              <Editor name="description" value={formData.description} onChange={(value) => handleTextEditor("description", value)} />
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-5">
          <div>
            <div className="mt-4 text-2xl font-semibold" htmlFor="">
              {/* Difficulty */}
            </div>
            <Difficulty difficultyLevel={formData.properties.difficultyLevel} diffLevel={(e) => { handlePropertyClick('difficultyLevel', e) }} />
          </div>
          <div>
            <div className="w- " >
              <div className="mt-4 text-2xl font-semibold">Initial Code </div>
              {/* <InitialCode name="predefinedCode" predefinedCode={formData.predefinedCode} handleCodeChange={handleCodeChange}  placeholder="Enter formatted code here..." /> */}
              {formData?.langId === "DSA" ? <InitialCode name="predefinedCode" predefinedCode={formData.predefinedCode} handleCodeChange={handleCodeChange} placeholder="Enter formatted code here..." /> 
            :  <textarea
              name="predefinedCode"
              value={formData.predefinedCode}
              onChange={handleTextarea}
              className="rounded-md outline-none resize-none bg-inputBg w-full text-[#3D4E74]  p-5 pl-5 pt-5 text-lg custom-scrollbar"
              placeholder="Enter your Description here... "
              rows={10}
              cols={90}
            ></textarea>}
            </div>          </div>
        </div>

        <div>

          <div>
            <div className="mt-10 text-2xl font-bold ">Test Cases </div>

            <div><EditTestcases testCases={formData.testCases} onDataUpdate={handleTestCaseUpdate} onDataDelete={handleTestCaseDelete} /></div>
          </div>

        </div>


        <div>
          <div className="mt-10 ">
            <div className="text-2xl font-bold">Constraints :</div>

            <div>
              <textarea
                name="constraints"
                value={formData.constraints}
                onChange={handleTextarea}
                className="rounded-md bg-inputBg  resize-none w-full text-[#3D4E74] outline-none p-5 pl-5 pt-5 text-lg h-32 custom-scrollbar"
                placeholder="Enter constrains  here..."
              ></textarea>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col justify-between mt-5">
              <div className="">
                <div className="text-xl font-bold">Sample Input :</div>
                <textarea
                  name="sampleInput"
                  value={formData.sampleInput}
                  onChange={handleTextarea}
                  placeholder="Sample input... "
                  className="h-20 pt-2 pl-5 text-lg rounded-md outline-none resize-none min-w-96 bg-inputBg"
                ></textarea>
              </div>
            </div>
            <div className="flex flex-col justify-between mt-5">
              <div className="flex flex-col justify-around">
                <div className="">
                  <div className="text-xl font-bold"> Sample Output :</div>

                  <textarea
                    name="sampleOutput"
                    value={formData.sampleOutput}
                    onChange={handleTextarea}
                    placeholder="Sample output..."
                    className="h-20 pt-2 pl-5 text-lg  rounded-md outline-none resize-none min-w-96 h-117 bg-inputBg"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end p-5 ">
            <div className="flex items-center text-xl font-bold bg-[#7EE46D] w-fit pl-2 p-1 pr-3 border-2 border-textBlue hover:shadow-xl rounded mr-6">
              <div>
                <FaPlus />
              </div>
              <button type="submit" onClick={handleLoading} className="pl-2">
                Update Problem{" "}
              </button>
            </div>{" "}
          </div>
        </div>
        <div className="w-5/6 m-auto mt-6 border border-slate-400 "></div>
        <div className="pb-16 mt-8 text-xl font-bold text-center">
          {formData.createdAt === formData.updatedAt ? "Created on: " + checkAMorPM(formData.createdAt) + " | " + dateConverter(formData.createdAt) : "Updated on: " + checkAMorPM(formData.updatedAt) + " | " + dateConverter(formData.updatedAt)}
        </div>
      </form>
      {/* </div> */}
    </div>
  );
}