import React, { useEffect, useState } from "react";
import { FaPlus, FaPen } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { TiTick } from "react-icons/ti";



const EditTestCases = ({ testCases, onDataUpdate, onDataDelete }) => {



const [data, setData] = useState(testCases || []);
const [selectedTopicIndex, setSelectedTopicIndex] = useState(null);
const [editable, setEditable] = useState(false);
const [newTopic, setNewTopic] = useState({
  testId: "",
  defaultInput: "",
  expectedOutput: "",
});

useEffect(() => {
  setData(testCases);
}, [testCases]);

const handleAddTopic = () => {
  const newTopicCopy = { ...newTopic, testId: data.length + 1 };
  const updatedTopics = [...data, newTopicCopy];
  setData(updatedTopics);
  setSelectedTopicIndex(data.length);
  setEditable(true);
  setNewTopic({
    testId: "",
    defaultInput: "",
    expectedOutput: "",
  });
};

const handleEditSyllabus = (index) => {
  setEditable(true);
  setSelectedTopicIndex(index);
  setNewTopic(data[index]);
};

const handleOk = () => {
  if (selectedTopicIndex !== null) {
    const updatedTopics = [...data];
    updatedTopics[selectedTopicIndex] = newTopic;
    setData(updatedTopics);
    onDataUpdate(updatedTopics); // Call the parent update method
  }
  setEditable(false);
};

const handleInputChange = (e, field) => {
  setNewTopic(prevTopic => ({
    ...prevTopic,
    [field]: e.target.value,
  }));
};

const handleDelete = (index, event) => {
  event.preventDefault();
  if (window.confirm("Are you sure you want to delete?")) {
    const updatedTopics = data.filter((_, i) => i !== index);
    setData(updatedTopics);
    onDataDelete(updatedTopics); // Call the parent delete method
  }
};

  

  return (
    <div className="mt-14 bg-inputBg rounded p-2">
      <div className="font-bold text-2xl">Test Cases:</div>
      <div className="flex flex-wrap justify-between bg-inputBg pt-5 mt-6 pl-6 pr-6">


        {Array.isArray(data) && data.map((topic, index) => (
          <div
            key={index}
            className={`w-1/4 p-2 rounded flex font-bold text-center text-lg mb-4 mr-1 cursor-pointer ${selectedTopicIndex === index ? "bg-[#699EDB] text-white" : "bg-bgBlue text-textBlue"
              }`}
            
          >
            {editable && selectedTopicIndex === index ? (
              <div
                className="w-full h-full  bg-[#699EDB] text-textBlue"
                onChange={(e) => handleInputChange(e, 'testId')}
              >
                <button className="w-3/4 flex" onClick={() => handleEditSyllabus(index)}> {newTopic.testId}</button>

              </div>
            ) : (
              <button className="w-3/4 flex" onClick={() => handleEditSyllabus(index)}>{topic.testId} </button>
            )}
            <button className="" onClick={(event) => handleDelete(index, event)}><MdDelete className='w-11' /></button>
          </div>
        ))}
        {editable && selectedTopicIndex === null && (
          <div
            className={`w-1/4 p-2 rounded font-bold text-center text-lg mb-4 mr-1 cursor-pointer bg-bgBlue text-textBlue`}
            onClick={() => handleEditSyllabus(newTopic.testId)}
          >
            {newTopic.testId}
          </div>
        )}





      </div>
      <div className="flex justify-end p-5">
        <div className="flex items-center text-xl font-bold bg-[#7EE46D] w-fit pl-2 p-1 pr-3 border-2 border-textBlue hover:shadow-xl rounded" onClick={() => handleAddTopic()}>
          <div>
            <FaPlus />
          </div>
          <div className="pl-1"> Add Test Case</div>
        </div>
      </div>
      {editable &&
        (editable || selectedTopicIndex !== null) && (
          <div className="w-full h-full rounded-md bg-inputBg pl-5 pt-5 pr-5 text-sm p-6 pb-10 mt-4">
            <div className="font-bold pr-4 pt-7 pl-6 text-lg mt-6">Sample Input:</div>
            <div
              className={`rounded-md bg-bgBlue  text-[#3D4E74]  p-5 pl-5 pt-5 text-lg custom-scrollbar border border-textBlue`}
            >
              {editable ? (
                <textarea
                  className="w-full h-full bg-bgBlue outline-none"
                  value={newTopic.defaultInput}
                  onChange={(e) => handleInputChange(e, 'defaultInput')}
                />
              ) : (
                data[selectedTopicIndex]?.defaultInput || newTopic.defaultInput
              )}
            </div>

            <div className="font-bold pr-4 pt-7 pl-6 text-lg mt-6">Expected Output:</div>
            <div
              className={`rounded-md bg-bgBlue  text-[#3D4E74]  p-5 pl-5 pt-5 text-lg custom-scrollbar border border-textBlue`}
            >
              {editable ? (
                <textarea
                  className="w-full h-full bg-bgBlue outline-none"
                  value={newTopic.expectedOutput}
                  onChange={(e) => handleInputChange(e, 'expectedOutput')}
                />
              ) : (
                data[selectedTopicIndex]?.expectedOutput || newTopic.expectedOutput
              )}
            </div>

            <div className="flex justify-end p-5">
              <div className="flex items-center text-xl font-bold bg-[#7EE46D] w-fit pl-2 p-1 pr-3 border-2 border-textBlue hover:shadow-xl rounded mr-6" onClick={handleOk}>
                <div>
                  <TiTick />
                </div>
                <div className="pl-2"> OK</div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default EditTestCases;
