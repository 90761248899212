import React, { useEffect, useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { TiTick } from "react-icons/ti";

const EditSyllabus = ({ setSyllabus, ...props }) => {
  const [data, setData] = useState([]);

  const [selectedTopicIndex, setSelectedTopicIndex] = useState(null);
  const [editable, setEditable] = useState(false);
  const [newTopic, setNewTopic] = useState({
    topicName: "",
    description: "",
    exampleCode: "",
  });

  useEffect(() => {
    setData(props.syllabus || []);
  }, [props.syllabus]);

  const handleAddTopic = () => {
    setData((prevData) => {
      const updatedTopics = [...prevData, newTopic];
      return updatedTopics;
    });
    setNewTopic({
      topicName: "",
      description: "",
      exampleCode: "",
    });
    setSelectedTopicIndex(data.length); // Assuming the index of the last added topic
    setEditable(true);
  };

  const handleEditSyllabus = (index) => {
    setEditable(true);
    setSelectedTopicIndex(index);
    if (index === data.length) {
      setNewTopic({
        topicName: "",
        description: "",
        exampleCode: "",
      });
    } else {
      setNewTopic(data[index]);
    }
  };

  const handleOk = () => {
    setEditable(false);
    const updatedTopics = [...data];
    if (selectedTopicIndex !== null && selectedTopicIndex < updatedTopics.length) {
      updatedTopics[selectedTopicIndex] = newTopic;
    }
    setData(updatedTopics);
    props.onDataUpdate(updatedTopics);
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setNewTopic((prevTopic) => ({
      ...prevTopic,
      [field]: value,
    }));
  };

  const handleDeleteTopic = (index) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this topic?");
    if (confirmDelete) {
      const updatedTopics = data.filter((_, i) => i !== index);
      setData(updatedTopics);
      props.onDataUpdate(updatedTopics);
      if (index === selectedTopicIndex) {
        setEditable(false);
        setSelectedTopicIndex(null);
        setNewTopic({
          topicName: "",
          description: "",
          exampleCode: "",
        });
      }
    }
  };

  return (
    <div className="mt-14 bg-inputBg rounded p-2">
      <div className="font-bold text-2xl">Topics:</div>
      <div className="flex flex-wrap justify-between bg-inputBg pt-5 mt-6 pl-6 pr-6">
        {Array.isArray(data) &&
          data.map((topic, index) => (
            <div
              key={index}
              className={`w-1/4 p-2 rounded font-bold text-center text-lg mb-4 mr-1 cursor-pointer ${
                selectedTopicIndex === index ? "bg-[#699EDB] text-white" : "bg-bgBlue text-textBlue"
              }`}
              onClick={() => handleEditSyllabus(index)}
            >
              {editable && selectedTopicIndex === index ? (
                <div
                  className="w-full h-full bg-[#699EDB] text-textBlue"
                  onChange={(e) => handleInputChange(e, "topicName")}
                >
                  {newTopic.topicName}
                </div>
              ) : (
                <div className="flex justify-between items-center">
                  <span>{topic.topicName}</span>
                  <FaTrash
                    className="ml-2 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteTopic(index);
                    }}
                  />
                </div>
              )}
            </div>
          ))}
        {editable && selectedTopicIndex === null && (
          <div
            className={`w-1/4 p-2 rounded font-bold text-center text-lg mb-4 mr-1 cursor-pointer bg-bgBlue text-textBlue`}
            onClick={() => handleEditSyllabus(data.length)}
          >
            {newTopic.topicName}
          </div>
        )}
      </div>
      <div className="flex justify-end p-5">
        <div
          className="flex items-center text-xl font-bold bg-[#7EE46D] w-fit pl-2 p-1 pr-3 border-2 border-textBlue hover:shadow-xl rounded"
          onClick={() => handleAddTopic()}
        >
          <div>
            <FaPlus />
          </div>
          <div className="pl-1"> Add Topic</div>
        </div>
      </div>
      {editable && (editable || selectedTopicIndex !== null) && (
        <div className="w-full h-full rounded-md bg-inputBg pl-5 pt-5 pr-5 text-sm p-6 pb-10 mt-4">
          <div className="font-bold pr-4 pt-7 pl-6 text-lg">Topic Name:</div>
          <div
            className={`w-80 h-11 rounded-md bg-bgBlue text-xl pt-2 pl-5 border border-textBlue`}
          >
            {editable ? (
              <input
                type="text"
                className="w-full h-full bg-bgBlue outline-none"
                value={newTopic.topicName}
                onChange={(e) => handleInputChange(e, "topicName")}
              />
            ) : (
              data[selectedTopicIndex]?.topicName || newTopic.topicName
            )}
          </div>

          <div className="font-bold pr-4 pt-7 pl-6 text-lg mt-6">Description:</div>
          <div
            className={`rounded-md bg-bgBlue text-[#3D4E74] p-5 pl-5 pt-5 text-lg custom-scrollbar border border-textBlue`}
          >
            {editable ? (
              <textarea
                className="w-full h-full bg-bgBlue outline-none"
                value={newTopic.description}
                onChange={(e) => handleInputChange(e, "description")}
              />
            ) : (
              data[selectedTopicIndex]?.description || newTopic.description
            )}
          </div>

          <div className="font-bold pr-4 pt-7 pl-6 text-lg mt-6">Sample Code:</div>
          <div
            className={`rounded-md bg-bgBlue text-[#3D4E74] p-5 pl-5 pt-5 text-lg custom-scrollbar border border-textBlue`}
          >
            {editable ? (
              <textarea
                className="w-full h-full bg-bgBlue outline-none"
                value={newTopic.exampleCode}
                onChange={(e) => handleInputChange(e, "exampleCode")}
              />
            ) : (
              data[selectedTopicIndex]?.exampleCode || newTopic.exampleCode
            )}
          </div>

          <div className="flex justify-end p-5">
            <div
              className="flex items-center text-xl font-bold bg-[#7EE46D] w-fit pl-2 p-1 pr-3 border-2 border-textBlue hover:shadow-xl rounded mr-6"
              onClick={handleOk}
            >
              <div>
                <TiTick />
              </div>
              <div className="pl-2"> OK</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditSyllabus;
