import { useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Dropdown({showDetails,...props}) {
  const [selectedDomain, setSelectedDomain] = useState('');

  function handleDomainClick(domain) {
    setSelectedDomain(domain);
    // console.log(domain);
    showDetails(domain);
    // Other logic you may want to perform on domain selection
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-3xl border-2 border-[#1C2951] bg-[#FFEB48] p-3 px-8 text-sm font-extrabold text-[21px] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          View
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-[#1C2951]" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="scroller absolute right-0 z-10 mt-2 w-40 origin-top-right divide-y divide-[#1C2951] bg-[#FCFCFA] border-4 border-[#1C2951] rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-auto max-h-48">
          <div>
            {Array.isArray(props?.domainList) && props?.domainList.map((domains, key) => (
              <Menu.Item key={key}>
                {({ active }) => (
                  <div
                    onClick={() => handleDomainClick(domains.domainId)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900 text-[20px] font-semibold' : 'text-[20px] font-semibold text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    {domains.domainId}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

