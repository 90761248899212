import React, { useState } from 'react';

const TagInput = ({addTag,removeTag,...props}) => {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      setTags([...tags, inputValue.trim()]);
      addTag([...tags, inputValue.trim()])
      setInputValue('');
    }
  };

  const handleTagRemove = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
    removeTag(tags.filter((tag) => tag !== tagToRemove))
  };

  return (
    <div className="mt-4">
      <label className="font-bold text-xl" htmlFor="">
        {props.title}
      </label>
      <br />
      <input
        type="text"
        id=""
        placeholder={props.placeholder}
        className="w-96 h-11 rounded-md bg-inputBg hover:border-2 hover:border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 text-sm"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
      />
      <div className="mt-2 text-wrap">
        {tags.map((tag, index) => (
          <span key={index} className="bg-inputBg mr-2 p-1 rounded">
           ({tag}
            <span className="tag-remove" onClick={() => handleTagRemove(tag)}>
              &#10006;)
            </span>
          </span>
        ))}
      </div>
    </div>
  );
};

export default TagInput;

