import React, { useEffect, useState } from 'react';

const Difficulty = ({diffLevel,...props}) => {
  const [selectedDifficulty, setSelectedDifficulty] = useState();

  useEffect(()=>{
    setSelectedDifficulty(props.difficultyLevel)
  },[props.difficultyLevel])

  const handleDifficultyClick = (event,difficulty) => {
    event.preventDefault(); 
    setSelectedDifficulty(difficulty);
    diffLevel(difficulty)
  };

  return (
    <div>
      <div className='font-bold text-xl'>Difficulty</div>
      <div className='w-96 bg-inputBg flex flex-col items-center p-5'>
        <div
          onClick={(e) => handleDifficultyClick(e,'beginner')}
          className={`w-5/6 bg-bgBlue p-2 rounded font-bold text-center hover:border border-blue-600 text-lg mb-4 mr-1 ${
            selectedDifficulty === 'beginner' ? 'selected' : ''
          }`}
          style={{
            boxShadow: selectedDifficulty === 'beginner' ? '0 0 10px rgba(0, 0, 0, 0.3)' : 'none',
            backgroundColor: selectedDifficulty === 'beginner' ? '#699EDB' : '#F2F3FF',
          }}
        >
          Beginner
        </div>
        <div
          onClick={(e) => handleDifficultyClick(e,'intermediate')}
          className={`w-5/6 bg-bgBlue p-2 rounded font-bold text-center hover:border border-blue-600 text-lg mb-4 mr-1 ${
            selectedDifficulty === 'intermediate' ? 'selected' : ''
          }`}
          style={{
            boxShadow: selectedDifficulty === 'intermediate' ? '0 0 10px rgba(0, 0, 0, 0.3)' : 'none',
            backgroundColor: selectedDifficulty === 'intermediate' ? '#699EDB' : '#F2F3FF',
          }}
        >
          Intermediate
        </div>
        <div
          onClick={(e) => handleDifficultyClick(e,'advanced')}
          className={`w-5/6 bg-bgBlue p-2 rounded font-bold text-center hover:border border-blue-600 text-lg mb-4 mr-1 ${
            selectedDifficulty === 'advanced' ? 'selected' : ''
          }`}
          style={{
            boxShadow: selectedDifficulty === 'advanced' ? '0 0 10px rgba(0, 0, 0, 0.3)' : 'none',
            backgroundColor: selectedDifficulty === 'advanced' ? '#699EDB' : '#F2F3FF',
          }}
        >
          Advanced
        </div>
        <div
          onClick={(e) => handleDifficultyClick(e,'master')}
          className={`w-5/6 bg-bgBlue p-2 rounded font-bold text-center hover:border border-blue-600 text-lg mb-4 mr-1 ${
            selectedDifficulty === 'master' ? 'selected' : ''
          }`}
          style={{
            boxShadow: selectedDifficulty === 'master' ? '0 0 10px rgba(0, 0, 0, 0.3)' : 'none',
            backgroundColor: selectedDifficulty === 'master' ? '#699EDB' : '#F2F3FF',
          }}
        >
          Master
        </div>
      </div>
    </div>
  );
};

export default Difficulty;
