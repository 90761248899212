import React, { useEffect, useState } from 'react';

const EditableTags = ({addTag,deleteTag,...props}) => {
  const [tags, setTags] = useState(props.data || []);

  useEffect(() => {
    setTags(props.data || []);
  }, [props.data]);

  const handleTagAdd = (newTag) => {
    if (newTag && !tags.includes(newTag)) {
      setTags([...tags, newTag]);
      addTag([...tags, newTag])
    }
  };

  const handleTagDelete = (tagIndex,event) => {
    event.preventDefault()
    const updatedTags = tags.filter((_, index) => index !== tagIndex);
    setTags(updatedTags);
    deleteTag(updatedTags)
  };

  return (
    <div className="">
      <div className="font-bold text-xl">{props.title}</div>
      <br />
      <div className="min-w-96 h-11 rounded-md bg-inputBg pt-2 pl-5 text-lg">
        {tags?.map((tag, index) => (
          <span key={index} className="bg-bgBlue mr-1">
            {tag}{' '}
            <button
            type='button'
              className="text-textBlue"
              onClick={(event) => handleTagDelete(index,event)}
            >
              &#10006;
            </button>
          </span>
        ))}
        <input
          type="text"
          className='bg-inputBg hover:border-none'
          placeholder="Add a new tag"
          // value={props.}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault(); // Prevent form submission
              handleTagAdd(e.target.value);
              e.target.value = ''; // Clear the input after adding the tag
            }
          }}
        />
      </div>
    </div>
  );
};

export default EditableTags;
