import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import InitialCode from "./components/InitialCode"

export default function ViewProblem() {

    const [problemData, setProblemData] = useState([])
    const [testcase, setTestcase] = useState(null);
    const [input, setInput] = useState(false);
    const [output, setOutput] = useState(false);
    const [id, setId] = useState(false)
    const handleTestCase = (index) => {
        setTestcase(index);
        setId(true);
        setInput(true);
        setOutput(true);
    };
    const toast = useToast()
    const { problem } = useParams()
    const navigate = useNavigate()
    const Base_Url=process.env.REACT_APP_LMS_URL

    async function getProblem() {
        try {
            const response = await axios.get(`${Base_Url}/${problem}/getproblem`);
            setProblemData(response.data.problem)

        }
        catch (error) {
            console.log(error)
        }

    }

    function handleNavigate() {
        navigate(`/edit-problem/${problem}`)
    }
    async function handleDelete() {
        const confirmation = prompt("Please type 'deleteproblem' to confirm deletion:");
        if (confirmation === 'deleteproblem') {
            try {
                await axios.delete(`${Base_Url}/${problem}/deleteproblem`)
                toast({
                    title: "Problem Deleted Successfully",
                    status: "success",
                    position: "top",
                    isClosable: true,
                });
                navigate("/")
            }
            catch (error) {
                console.log(error);
                toast({
                    title: "An Error Occoured",
                    status: "error",
                    position: "top",
                    isClosable: true,
                });
            }
        } else {
            toast({
                title: "Deletion Cancelled",
                status: "warning",
                position: "top",
                isClosable: true,
            });
        }
    }

    function dateConverter(date) {
        // const [year, month, day] = date.split('-');
        // const formattedDate = `${day}-${month}-${year}`;
        if (!date) {
            return null
        }
        const apiDate = date.slice(0, 10);
        const [year, month, day] = apiDate.split('-')
        const formattedDate = `${month}-${day}-${year}`;
        return formattedDate;

    }

    function checkAMorPM(timeString) {
        if (!timeString) {
            return null;
        }
        const hour = parseInt(timeString.substring(11, 13), 10);
        if (hour >= 12) {
            return timeString.slice(11, 16) + " " + "PM";
        } else {
            return timeString.slice(11, 16) + " " + "AM";
        }
    }



    useEffect(() => {
        getProblem()
    }, [])

    return (
        <div className="h-full pl-16 pr-16 text-lg bg-bgBlue w text-textBlue font-raleway font-semibold font-[raleway]">
            <div className="pt-8 text-3xl font-bold text-center">
                VIEW{" "}
                <div>
                    <IoIosArrowBack />
                </div>{" "}
            </div>
            <div className="flex justify-end p-5 "  >
                <button className="flex items-center text-xl font-bold bg-[#7EE46D] w-fit pl-2 p-1 pr-3 border-2 border-textBlue hover:shadow-xl rounded mr-6" onClick={handleNavigate}>
                    <div>
                        <MdModeEdit />
                    </div>
                    <div className="pl-2"> Edit</div>
                </button>
                <button className="flex items-center text-xl font-bold bg-[#E04E4E] w-fit pl-2 p-1 pr-3 border-2 border-textBlue hover:shadow-xl rounded" onClick={handleDelete}>
                    <div>
                        <MdDelete />
                    </div>
                    <div className="pl-1"> Delete</div>
                </button>
            </div>
            <div>
                <form action="submit ">
                    <div>
                        <div className="flex justify-between ">
                            <div className="">
                                <div className="flex">
                                    <div className="text-2xl font-semibold" htmlFor="domainId">
                                        Domain ID:
                                    </div>
                                    <div className="pl-3 text-2xl font-semibold">{problemData.parentDomainId}</div>
                                </div>
                                <div className="flex">
                                    <div className="text-2xl font-semibold" htmlFor="domainId">
                                        Problem ID:
                                    </div>
                                    <div className="pl-3 text-2xl font-semibold">{problemData.problemId}</div>
                                </div>
                            </div>
                            <div>
                                <div className="flex">
                                    <div className="text-2xl font-semibold" htmlFor="domainId">
                                        Topic Name :
                                    </div>
                                    <div className="pl-3 text-2xl font-semibold">
                                        {problemData?.properties?.subdomain}{" "}
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="text-2xl font-semibold" htmlFor="domainId">
                                        Problem Name:
                                    </div>
                                    <div className="pl-3 text-2xl font-semibold">
                                        {" "}
                                        {problemData.problemName}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-14 ">
                        <div className="text-2xl font-bold">Heading:</div>

                        <div className="rounded-md bg-inputBg  text-[#3D4E74]  p-5 pl-5 pt-5 text-lg custom-scrollbar">
                            {problemData.problemHeading}
                        </div>
                    </div>
                    <div className="mt-14 ">
                        <div className="text-2xl font-bold">Description :</div>
                        {/* <br /> */}
                        <div className="rounded-md bg-inputBg  text-[#3D4E74]  p-5 pl-5 pt-5 text-lg custom-scrollbar" dangerouslySetInnerHTML={{ __html: problemData.description}}>
                            {/* {problemData.description} */}
                        </div>
                    </div>

                    <div className="flex justify-between mt-5">
                        <div>
                            <div className="mt-4 text-2xl font-semibold" htmlFor="">
                                Difficulty
                            </div>
                            <div className="px-16 py-5 text-lg rounded-md w-72 min-w-96 h-117 bg-inputBg ">
                                <div className="p-2 text-center bg-white ">
                                    {problemData.properties?.difficultyLevel}
                                    <div> </div>
                                </div>
                            </div>
                            <div className="mt-2 text-2xl font-semibold" htmlFor="">
                                Points
                            </div>
                            <div className="px-16 py-5 text-lg rounded-md w-72 min-w-96 h-117 bg-inputBg ">
                                <div className="p-2 text-center bg-white ">
                                    {problemData.properties?.pointsToBeEarned}
                                    <div> </div>
                                </div>
                            </div>
                            <div className="mt-2 text-2xl font-semibold" htmlFor="">
                                Language
                            </div>
                            <div className="px-16 py-5 text-lg rounded-md w-72 min-w-96 h-117 bg-inputBg ">
                                <div className="p-2 text-center bg-white ">
                                    {problemData?.langId}
                                    <div> </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-3/5 ">
                            <div className="mt-4 text-2xl font-semibold">Initial Code </div>
                            {problemData?.langId === "DSA" ? <InitialCode name="predefinedCode" predefinedCode={problemData.predefinedCode} /> :
                                <textarea name="predefinedCode" value={problemData.predefinedCode} rows={11} cols={82} className="p-4 text-lg rounded-md   h- bg-inputBg overflow-y-auto resize-none">


                                </textarea>
                            }

                        </div>
                    </div>

                    {/* <div className="text-2xl font-bold mt-14 "> </div> */}
                    <div className="w-full h-full p-6 pt-5 pb-10 pl-5 pr-5 text-sm rounded-md mt-7 bg-inputBg">
                        <div className="pl-6 pr-4 text-lg font-bold pt-7">
                            Test Case{" "}
                            <div className="flex flex-wrap justify-between">
                                {Array.isArray(problemData?.testCases) && problemData?.testCases.map((topics, index) => (
                                    <div
                                        key={index}
                                        className="flex flex-col py-5 mb-4 mr-1 text-lg font-bold justify-center text-center rounded w-72 px-38 bg-bgBlue"
                                        onClick={() => handleTestCase(index)}
                                    >
                                        {topics.testId}

                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* <div className="flex items-center pl-6 pt-7">
                            <div className="pr-4 text-lg font-bold">Test Case Id :</div>
                            <div
                                className="pt-2 pl-5 text-xl rounded-md w-80 h-11 bg-bgBlue"
                                onClick={() => setId(true)}
                            >
                                {testcase !== null && problemData?.testCases[testcase]?.testId}

                            </div>
                        </div> */}

                        <div className="pl-6 pt-7">
                            <div className="pr-4 text-lg font-bold">Test Case Input :</div>
                            <div
                                className="w-full pt-5 pb-5 pl-5 text-sm rounded-md pr-7 bg-bgBlue"
                                onClick={() => setInput(true)}
                            >
                                {testcase !== null && problemData?.testCases[testcase]?.defaultInput}
                            </div>
                        </div>

                        <div className="pl-6 pt-7">
                            <div className="pr-4 text-lg font-bold">Test Case Output :</div>
                            <div
                                className="pt-5 pb-5 pl-5 text-sm rounded-md bg-bgBlue"
                                onClick={() => setOutput(true)}
                            >
                                {testcase !== null && problemData?.testCases[testcase]?.expectedOutput}
                            </div>
                        </div>

                    </div>
                    <div>
                        <div>
                            <div className="mt-8 text-xl font-bold">Constraints </div>
                            <div className="w-full p-6 text-sm rounded-md h-117 bg-inputBg pl-50 pt-30 pr-50 pb-30">
                                <div className="flex flex-col w-96 bg-inputBg font-raleway text-lg">
                                    <ul>
                                        <li>{problemData.constraints}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div className="flex flex-col justify-between mt-12 ">
                            <div className="">
                                <div className="font-bold ">Sample Input :</div>
                                {/* <br /> */}
                                <div className="h-20 pt-2 pl-5 text-lg rounded-md min-w-96 bg-inputBg">
                                    <ul>
                                        <li>{problemData.sampleInput}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between mt-12">
                            <div className="flex flex-col justify-around">
                                <div className="">
                                    <div className="text-xl font-bold"> Sample Output :</div>
                                    {/* <br /> */}
                                    <div className="h-20 pt-2 pl-5 text-lg rounded-md min-w-96 h-117 bg-inputBg">
                                        <ul>
                                            <li>{problemData.sampleOutput}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="w-5/6 m-auto mt-6 border border-slate-400 "></div>
            <div className="pb-16 mt-8 text-xl font-bold text-center">
                {problemData.createdAt === problemData.updatedAt ? "Created on: " + checkAMorPM(problemData.createdAt) + " | " + dateConverter(problemData.createdAt) : "Updated on: " + checkAMorPM(problemData.updatedAt) + " | " + dateConverter(problemData.updatedAt)}

            </div>
        </div>
    );
}