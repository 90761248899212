const languages=[
    {
        langId:"java",
        versionIndex:"4"
    },
    {
        langId:"c",
        versionIndex:"4"
    },
    {
        langId:"cpp",
        versionIndex:"4"
    },
    {
        langId:"python3",
        versionIndex:"3"
    },
    {
        langId:"ruby",
        versionIndex:"3"
    },
    {
        langId:"nodejs",
        versionIndex:"3"
    },
    {
        langId:"DSA",
        versionIndex:"1"
    }
]

export default languages;