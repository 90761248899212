import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { FaCircleNotch } from "react-icons/fa6";
import Features from "./components/Features";
import Difficulty from "./components/Difficulty";
import TagInput from "./components/TagInput";
import Syllabus from "./components/Syllabus";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

const AddDomain = () => {
  const [domainData, setDomainData] = useState({
    domainId: '',
    domainName: '',
    totalProblems: 0,
    tags: [],
    logo: '',
    introductoryCode: '',
    overview: {
      about: '', keyFeatures: '',
      prerequisites: '',
    },
    difficultyLevel: 'beginner',
    timeToComplete: '',
    syllabus: [{ topicName: '', description: '', exampleCode: '' }],
  });

  const toast=useToast()
  const Base_Url=process.env.REACT_APP_LMS_URL
  // const handleInputChange = (e) => {
  //   setDomainData({ ...domainData, domainId: e.target.value});
  // };
  const handleFeatures = (feature) => {
    // setDomainData.keyFeatures(feature)
    const updatedDomainData = { ...domainData };
    updatedDomainData.overview.keyFeatures = feature;
    setDomainData(updatedDomainData);
  }
  const handleSyllabus = (syllabus) => {
    setDomainData((prevDomainData) => ({
      ...prevDomainData,
      syllabus: syllabus,
    }));
  };

  const handlePrereq = (prerequisite) => {
    const updatedDomainData = { ...domainData };
    updatedDomainData.overview.prerequisites = prerequisite;
    setDomainData(updatedDomainData);
  }
  const removePrereq = (prerequisite) => {
    const updatedDomainData = { ...domainData };
    updatedDomainData.overview.prerequisites = prerequisite;
    setDomainData(updatedDomainData);
  }
  const handleTags = (tag) => {
    const updatedDomainData = { ...domainData };
    updatedDomainData.tags = tag;
    setDomainData(updatedDomainData);
  }
  const removeTags = (tag) => {
    const updatedDomainData = { ...domainData };
    updatedDomainData.tags = tag;
    setDomainData(updatedDomainData);
  }

  const handleDiffLevel = (diffLevel) => {
    const updatedDomainData = { ...domainData };
    updatedDomainData.difficultyLevel = diffLevel;
    setDomainData(updatedDomainData)
    // console.log(diffLevel)
    // console.log()
  }

  const navigate = useNavigate()

  // console.log(formatDateAndTime());


   const handleSubmit = async(event) => {
    // var currentDate = new Date();

    // var hours = currentDate.getHours();
    // var minutes = currentDate.getMinutes();
    // var ampm = hours >= 12 ? 'PM' : 'AM';

    // hours = hours % 12;
    // hours = hours ? hours : 12;


    // minutes = minutes < 10 ? '0' + minutes : minutes;

    // var month = currentDate.getMonth() + 1;
    // var day = currentDate.getDate();
    // var year = currentDate.getFullYear();

    // var formattedDateAndTime = hours + ':' + minutes + ' ' + ampm + ' | ' + month + '-' + day + '-' + year;

    // event.preventDefault();

    if (!domainData.domainId.trim()) {
      // console.log("Enter all values")
      toast({
        title: "Enter all fields",
        status: "warning",
        position: "top",
        isClosable: true,
      });
    }
    else {
      await axios
        .post(
          `${Base_Url}/createdomain`,

          domainData
        )
        .then((response) => {
          // console.log("POST request successful:", response.data);
          toast({
            title: "Domain Added Successfully ",
            status: "success",
            position: "top",
            isClosable: true,
          });
        })
        .catch((error) => {
          console.error("Error making POST request:", error);
          toast({
            title: "An Error Occoured",
            status: "error",
            position: "top",
            isClosable: true,
          });
        });
      navigate("/")

    }

  }
  const [isLoading, setIsLoading] = useState(false);
  const handleLoading = () => {
    if (!domainData.domainId.trim()) {
      // If domainId is empty, don't make it visible
      setIsLoading(false);
    } else {
      // If domainId is not empty, make it loading and visible
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000); // Show loading div for 2-3 seconds
    }
  };
  return (
    <div className="bg-bgBlue w text-textBlue h-full font-raleway pr-16 pl-16 font-[raleway]">
        {isLoading && (
       <div className="fixed inset-0 flex items-center justify-center bg-[rgba(255,255,255,0.5)] backdrop-blur-sm">
       <div className="flex flex-col items-center justify-center font-extrabold text-6xl">
         <div className="animate-spin">
           <FaCircleNotch />
         </div>
         <div className="m-10">
           LOADING<span className="smooth-loading">.....</span>
         </div>
       </div>
     </div>
     
      )}
      <div className="font-bold text-center text-3xl pt-8">ADD DOMAIN</div>
      <div className="flex justify-end p-5 ">
        {/* <div className="flex items-center text-xl font-bold bg-[#E04E4E] w-fit pl-2 p-1 pr-3 border-2 border-textBlue hover:shadow-xl rounded">
          <div>
            <MdDelete />
          </div>
          <div className="pl-1"> Delete</div>
        </div> */}
      </div>
      <div>
        <form onSubmit="submit" >
          <div className="flex justify-between ">
            <div className="">
              <label className="font-bold  text-2xl" htmlFor="domainId">
                Domain ID:
              </label>
              <br />
              <input
                type="text"
                id="domainId"
                placeholder="Enter Domain id..."
                onChange={(e) => { setDomainData({ ...domainData, domainId: e.target.value }); }}
                value={domainData.domainId}
                className="w-96 h-11 rounded-md bg-inputBg hover:border-2 border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 text-sm"
              />
            </div>
            <div className="">
              <label className="font-bold  text-2xl" htmlFor="domainName">
                Domain Name:
              </label>
              <br />
              <input
                type="text"
                id="domainName"
                placeholder="Enter Domain name..."
                onChange={(e) => { setDomainData({ ...domainData, domainName: e.target.value }); }}
                value={domainData.domainName}
                className="w-96 h-11 rounded-md bg-inputBg hover:border-2 hover:border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 text-sm"
              />
            </div>
          </div>
          <div className="mt-14 ">
            <label className="font-bold  text-2xl" htmlFor="overview">
              Overview:
            </label>
            <br />
            <textarea
              className="rounded-md bg-inputBg hover:border-2 border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 pt-5 text-sm custom-scrollbar resize-none"
              name="overview"
              placeholder="Explain your overview here..."
              id="overview"
              cols="188"
              rows="7"
              onChange={(e) => { setDomainData({ ...domainData, overview: { ...domainData.overview, about: e.target.value } }); }}
              value={domainData.overview.about}
            ></textarea>
          </div>
          <Features featureData={handleFeatures} />
          <Syllabus syllabusData={handleSyllabus} />
          <div className="flex justify-between mt-12 ">
            <div className="">
              <label className="font-bold  text-xl" htmlFor="">
                Cheat Sheet:
              </label>
              <br />
              <input
                type="text"
                id=""
                placeholder="Enter Link..."
                // value={}
                className="w-96 h-11 rounded-md bg-inputBg hover:border-2 border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 text-sm"
              />
            </div>
            <TagInput title="Prerequisites" placeholder="Enter prerequisites here..." addTag={handlePrereq} removeTag={removePrereq} />
          </div>
          <div className="mt-12 flex justify-between">
            <div><Difficulty diffLevel={handleDiffLevel} /></div>
            <div className="flex flex-col justify-around">
              <TagInput title="Tags" placeholder="Enter Tags here..." addTag={handleTags} removeTag={removeTags} />
              <div className="">
                <label className="font-bold  text-xl" htmlFor="">
                  Time to complete:
                </label>
                <br />
                <input
                  type="text"
                  id=""
                  placeholder="Enter Time to complete in hours"
                  value={domainData.timeToComplete}
                  onChange={(e) => { setDomainData({ ...domainData, timeToComplete: e.target.value }); }}
                  className="w-96 h-11 rounded-md bg-inputBg hover:border-2 hover:border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 text-sm"
                />
              </div></div></div>
          <div className="flex flex-col  mt-12 ">
            <div className="font-bold text-[24px] ">Logo:</div>
            <input
              type="text"
              id=""
              placeholder="Enter Logo url"
              value={domainData.logo}
              onChange={(e) => { setDomainData({ ...domainData, logo: e.target.value }); }}
              className="w-96 h-11 rounded-md bg-inputBg hover:border-2 hover:border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 text-sm"
            />
          </div>
        </form>


        <div className="border border-slate-400 w-5/6 m-auto mt-6 "></div>
        <div className="flex justify-end p-5 mt-3">
          <button type="submit"
            className="flex items-center text-xl font-bold bg-[#7EE46D] w-fit pl-2 p-1 pr-3 border-2 border-textBlue hover:shadow-xl rounded">

            <div className="pl-1 flex" onClick={(event) => {handleSubmit(event); handleLoading();}}><FaPlus />  Add Domain</div>
          </button>
        </div>

        {/* <div className="font-bold text-xl mt-8 pb-16 text-center">Created on:  12:05 AM  |  11-25-2023</div>  */}
      </div>
    </div>
  );
};

export default AddDomain;
