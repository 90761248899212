import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom"
import { MdModeEditOutline } from 'react-icons/md';

function DPList({ probState, ...props }) {
    const [space, setSpace] = useState(false)
    // console.log(props.list[0])


    // const navigate = useNavigate();


    function dateConverter(date) {
        // const [year, month, day] = date.split('-');
        // const formattedDate = `${day}-${month}-${year}`;
        const apiDate = date.slice(0, 10);
        const [year, month, day] = apiDate.split('-')
        const formattedDate = `${month}-${day}-${year}`;
        return formattedDate;
    }

    function checkAMorPM(timeString) {
        const hour = parseInt(timeString.substring(11, 13), 10);
        if (hour >= 12) {
            return timeString.slice(11, 16) + " " + "PM";
        } else {
            return timeString.slice(11, 16) + " " + "AM";
        }
    }




    return (
        <div className='h-[450px] '>
            <div className='flex  bg-[#FBFAFF] p-3 border-[#1C2951] border-4 text-[#1C2951] rounded-xl font-semibold text-[30px]  '>
                {
                    probState === "domain" ? <> <div className='ml-10'>ID</div>
                        <div className='ml-12 '>Domain Name</div>
                        <div className='ml-60'>Overview</div>
                        <div className='ml-64'>Time & Date</div>
                        <div className='ml-40'>Actions</div></> : <>
                        <div className='ml-10'>ID</div>
                        <div className='ml-24'>Domain</div>
                        <div className='ml-32 '>Problem Name</div>
                        {/* <div className='ml-40'>Overview</div> */}
                        <div className='ml-16'>Difficulty Level</div>
                        <div className='ml-10'>Time & Date</div>
                        <div className='ml-44'>Actions</div></>
                }

            </div>

            <div className='adminscroll   p-1  overflow-auto max-h-[360px]'>
                {Array.isArray(props.list) && props.list.map((domain, key) => {
                    return (
                        //problems
                        probState === "problem" ?
                            <div className='flex  text-[#1C2951] font-normal p-1  space-x-4   '>
                                <div className='bg-[#EFEDF5] w-[89px] h-[69px] rounded-xl  place-items-center text-[30px] flex justify-center'>{key + 1}</div>
                                <div className='bg-[#EFEDF5] w-[244px] h-[69px] rounded-xl  place-items-center text-[24px] justify-center flex'>{domain?.parentDomainId}</div>
                                <div className='bg-[#EFEDF5] w-[260px] h-[69px] rounded-xl  place-items-center text-[24px] justify-center flex'>{domain?.problemName?.length > 20 ? domain?.problemName.slice(0,20)+"..." : domain?.problemName}</div>

                                <div className='bg-[#EFEDF5] w-[219px] h-[69px] rounded-xl place-items-center overflow-hidden text-[20px] flex justify-center'>{domain.properties?.difficultyLevel
                                }</div>


                                <div className='text-[24px] w-[265px] place-items-center flex bg-[#EFEDF5] rounded-xl p-2 justify-center'>{checkAMorPM(domain?.createdAt)} | {dateConverter(domain?.createdAt)}</div>
                                <div className='flex space-x-3'>
                                    <Link to={`view-problem/${domain.problemId}`}>
                                        <button className='bg-[#EFEDF5] w-[152px] h-[70px] font-semibold text-[24px] rounded-xl' >View</button>
                                    </Link>
                                    <Link to={`edit-problem/${domain.problemId}`}>
                                        <button className='bg-[#EFEDF5] w-[152px] h-[70px] font-semibold text-[24px] flex place-items-center place-content-evenly rounded-xl'> <MdModeEditOutline /> Edit</button>
                                    </Link>
                                </div>
                            </div> :
                            //domains
                            <div className='flex  text-[#1C2951] font-normal p-1  space-x-4 '>
                                <div className='bg-[#EFEDF5] w-[89px] h-[69px] rounded-xl  place-items-center text-[30px] flex justify-center'>{key + 1}</div>
                                <div className='bg-[#EFEDF5] w-[244px] h-[69px] rounded-xl  place-items-center text-[24px] justify-center flex'>{domain?.domainName}</div>
                                <div className='bg-[#EFEDF5] w-[505px] h-[69px] rounded-xl pt-8 pb-8 p-2 place-items-center overflow-hidden text-[16px] font-normal flex justify-center'>{domain.overview?.about && <>
                                    {domain?.overview?.about.length > 180 ? domain.overview.about.slice(0, 180) + "..." : domain?.overview?.about }
                                </>}</div>
                                <div className='text-[24px] w-[265px] place-items-center flex bg-[#EFEDF5] rounded-xl p-2 justify-center'> {checkAMorPM(domain?.createdAt)} | {dateConverter(domain?.createdAt)}</div>
                                <div className='flex space-x-3'>
                                    <Link to={`view-domain/${domain.domainId}`}>
                                        <button className='bg-[#EFEDF5] w-[152px] h-[70px] font-semibold text-[24px] rounded-xl' >View</button></Link>
                                    <Link to={`edit-domain/${domain.domainId}`}>
                                        <button className='bg-[#EFEDF5] w-[152px] h-[70px] font-semibold text-[24px] flex place-items-center place-content-evenly rounded-xl' > <MdModeEditOutline />
                                            Edit
                                        </button>
                                    </Link>
                                </div>
                            </div>
                    )
                })}
            </div>
        </div>
    )
}


export default DPList;
