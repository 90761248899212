import React from 'react'
import { CgProfile } from "react-icons/cg";
import Logo from "./Logo.png"

function AdminNavbar() {
  return (
    
    <div className='flex place-content-between bg-[#FBFAFF] p-2 pl-16 pr-16 border-[#1C2951] border-4 text-[#1C2951] rounded-xl font-[raleway]'>
        <div className='text-[20px] place-content-center place-items-center flex p-3'><img src={Logo} alt="" width={50} height={50} /></div>
        <div className='font-semibold text-[40px]'>ADMIN PANEL</div>
        <div className='text-[20px] place-items-center flex p-3'><CgProfile size={25} className='' /></div>
    </div>
  )
}

export default AdminNavbar