import React, { useState } from 'react'
import AdminNavbar from './AdminNavbar'
import { IoAddSharp } from "react-icons/io5";
import Dropdown from './Dropdown';
import DPList from './DPList';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import { useToast } from "@chakra-ui/react";

function AdminPanel() {
    const [isDomain, setIsDomain] = useState("")
    const [details, setDetails] = useState([])
    const [domains, setDomains] = useState([])
    const [loading, setLoading] = useState(false)
    const toast = useToast();
    const Base_Url=process.env.REACT_APP_LMS_URL
    const fetchData = async () => {
        setIsDomain("domain");
        setLoading(true)
        await axios.get(`${Base_Url}/alldomains`).then(response => { setDetails(response.data.domains); setLoading(false); }).catch(error => {
            console.log(error); toast({
                title: "Error fetching domains",
                status: "error",
                position: "top",
                isClosable: true,
            });
        })
    }

    const fetchProblemData = async (detail) => {
        setIsDomain("problem")
        setLoading(true)
        const value = detail
        await axios.get(`${Base_Url}/${value}/problems`).then(response => { setDetails(response.data.problems); setLoading(false); }).catch(error => { console.log(error);toast({
            title: "Error fetching problems",
            status: "error",
            position: "top",
            isClosable: true,
          }); })
    }
    async function fetchDomain() {
        try {
            const response = await axios.get(`${Base_Url}/alldomains`);


            setDomains(response.data.domains)

        }
        catch (error) {
            console.error("error fetching domain")
            toast({
                title: "Error fetching problems",
                status: "error",
                position: "top",
                isClosable: true,
              });
        }

    }





    // useEffect(() => {
    //     fetchData()
    //     fetchProblemData()
    // }, [])
    // console.log(isDomain)

    const navigate = useNavigate();
    const handleDomainAddClick = () => {
        navigate('/add-domain')

    }
    const handleProblemAddClick = () => {
        navigate('/add-problem')

    }
    async function problemHandler() {
        // console.log('fetchProblemData function is created');
        setIsDomain("problem")
        // setDetails(ProblemArray)
        setLoading(true)
        await axios.get(`${Base_Url}/allproblems`).then(response => { setDetails(response.data.problems.reverse()); setLoading(false); }).catch(error => { console.log(error) })
    }

    function detailsHandler(detail) {
        const value = detail;

        const filteredData = isDomain === "problem" ? details.filter(item => item.domainId === value) : details.filter(item => item.domainId === value)
        setDetails(filteredData);
    }

    return (
        <div className='font-[raleway]'>
            <AdminNavbar />
            <div className='flex m-14 bg-[#FBFAFF] text-[#1C2951] p-5 rounded-xl place-content-around border-[#1C2951] border-[3px]'>
                <button className={isDomain === "domain" ? 'font-bold text-[30px] underline ' : 'font-bold text-[30px] text-[#454C62]'} onClick={() => { fetchData(); fetchDomain() }}>Domain</button>
                <div className={isDomain === "domain" ? 'flex place-items-center' : 'flex place-items-center text-[#454C62] pointer-events-none'}>
                    <Dropdown showDetails={detailsHandler} domainList={domains} />
                </div>
                <button className={isDomain === "domain" ? 'bg-[#7EE46D] rounded-3xl border-[#1C2951] border-[3px] flex  place-items-center  font-extrabold text-[20px] p-2 pl-3 pr-3 hover:bg-[#FBFAFF]' : 'rounded-3xl border-[#1C2951] border-[3px] flex  place-items-center  font-extrabold text-[20px] p-2 pl-3 pr-3 bg-gray-400 text-[#454C62]'} onClick={handleDomainAddClick} disabled={isDomain === "problem" || isDomain === ""}><IoAddSharp size={30} /> Add Domain</button>
                <button onClick={() => { problemHandler(); fetchDomain() }} className={isDomain === "problem" ? 'font-bold text-[30px] underline' : 'font-bold text-[30px] text-[#454C62]'}>Problem</button>
                <div className={isDomain === "problem" ? 'flex place-items-center' : 'flex place-items-center text-[#454C62] pointer-events-none'}>
                    <Dropdown showDetails={fetchProblemData} domainList={domains} />
                </div>
                <button className={isDomain === "problem" ? 'bg-[#7EE46D] rounded-3xl border-[#1C2951] border-[3px] flex  place-items-center  font-extrabold text-[20px] p-2 pl-3 pr-3 hover:bg-[#FBFAFF]' : 'rounded-3xl border-[#1C2951] border-[3px] flex  place-items-center  font-extrabold text-[20px] p-2 pl-3 pr-3 bg-gray-400 text-[#454C62]'} onClick={handleProblemAddClick} disabled={isDomain === "domain" || isDomain === ""} ><IoAddSharp size={30} /> Add Problem</button>
            </div>
            {isDomain === "" ? <p className=' text-center  font-bold text-[150px] text-[#1c2951] '>DASHBOARD</p> : <div className='border-[1px] border-[#1C2951] rounded-xl'>
                {loading ? <div className='flex place-content-center'><ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#0229bf', '#0229bf', '#0229bf', '#0229bf', '#0229bf']}
                /></div> :
                    <DPList list={details} probState={isDomain} />

                }
            </div>}


        </div>
    )
}

export default AdminPanel