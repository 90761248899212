import React, { useEffect, useState } from "react";
import { FaPen, FaPlus } from "react-icons/fa6";
import { TiTick } from "react-icons/ti";
import { FaCircleNotch } from "react-icons/fa6";
import Difficulty from "./components/Difficulty";
import EditSyllabus from "./components/EditSyllabus";
import EditableTags from "./components/EditableTags";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

const EditDomain = () => {
  const [overviewEditable, setOverviewEditable] = useState(false);
  const [featuresEditable, setFeaturesEditable] = useState(false);
  const [cheatSheetEditable, setCheatSheetEditable] = useState(false);
  const [timeToCompleteEditable, setTimeToCompleteEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const [cheatSheetContent, setCheatSheetContent] = useState("www.example.com//");
  const [domainData, setDomainData] = useState({
    domainId: '',
    domainName: '',
    totalProblems: 0,
    tags: [],
    logo: '',
    introductoryCode: '',
    overview: {
      about: '', keyFeatures: [],
      prerequisites: '',
    },
    difficultyLevel: 'beginner',
    timeToComplete: '',
    syllabus: [{ topicName: '', description: '', exampleCode: '' }],
    logo:''
  });
  const [updatedData, setUpdatedData] = useState({
    domainId: '',
    domainName: '',
    totalProblems: 0,
    tags: [],
    logo: '',
    introductoryCode: '',
    overview: {
      about: '',
      keyFeatures: [],
      prerequisites: '',
    },
    difficultyLevel: 'beginner',
    timeToComplete: '',
    syllabus: [{ topicName: '', description: '', exampleCode: '' }],
    logo:''
  });
const toast=useToast()
const Base_Url=process.env.REACT_APP_LMS_URL
  // console.log(domain)

  const { domain } = useParams()
  useEffect(() => {
    const fetchPreviousData = async () => {
      try {
        const response = await axios.get(`${Base_Url}/${domain}/getdomain`);
        setDomainData(response.data.domain);
        setUpdatedData(response.data.domain)
        // console.log(response.data.domain)
      } catch (error) {
        console.error('Error fetching previous data:', error);
        toast({
          title: "An Error Occoured",
          status: "error",
          position: "top",
          isClosable: true,
        });
        // Handle errors appropriately
      }
    };

    fetchPreviousData();
  }, [domain]);

  const handleInputChange = (field, value) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleOverviewChange = (field, value) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      overview: {
        ...prevData.overview,
        [field]: value,
      },
    }));
  };
  const handleDataUpdate = (updatedSyllabus) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      syllabus: updatedSyllabus,
    }));
    // You can perform other actions with the updated data if needed
  };

  const navigate=useNavigate()

  const  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.put(`${Base_Url}/${domain}/updatedomain`, updatedData);
      // console.log('Update successful:', response.data);
      toast({
        title: "Domain Updated Successfully ",
        status: "success",
        position: "top",
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating data:', error);
      toast({
        title: "Error updating domain ",
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
    navigate("/")
  };

  function dateConverter(date) {
    // const [year, month, day] = date.split('-');
    // const formattedDate = `${day}-${month}-${year}`;
    if(!date){
      return null
    }
    else {
    const apiDate = date.slice(0, 10);
    const [year, month, day] = apiDate.split('-')
    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
    }
  }

  function checkAMorPM(timeString) {
    if(!timeString){
      return null;
    }
    const hour = parseInt(timeString.substring(11, 13), 10);
    if (hour >= 12) {
        return timeString.slice(11,16) + " " + "PM";
    } else {
        return timeString.slice(11,16) + " " + "AM";
    }
}
const handleLoading = () => {
  if (!domainData.domainId.trim()) {
    // If domainId is empty, don't make it visible
    setIsLoading(false);
  } else {
    // If domainId is not empty, make it loading and visible
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Show loading div for 2-3 seconds
  }
};

  return (
    <>
      <div className="bg-bgBlue w text-textBlue text-lg h-full font-raleway pr-16 pl-16 font-[raleway]">
      {isLoading && (
       <div className="fixed inset-0 flex items-center justify-center bg-[rgba(255,255,255,0.5)] backdrop-blur-sm">
       <div className="flex flex-col items-center justify-center font-extrabold text-6xl">
         <div className="animate-spin">
           <FaCircleNotch />
         </div>
         <div className="m-10">
           LOADING<span className="smooth-loading">.....</span>
         </div>
       </div>
     </div>
     
      )}
        <div className="font-bold text-center text-3xl pt-8">EDIT</div>

        <div className="pt-6">
          <form onSubmit={handleSubmit}>
            <div className="flex justify-between ">
              <div className="flex">
                <div className="font-bold  text-2xl" htmlFor="domainId">
                  Domain ID:
                </div>
                <div className="font-bold text-2xl pl-3">{domainData.domainId}</div>
              </div>
              <div className="flex">
                <div className="font-bold  text-2xl" htmlFor="domainId">
                  Domain Name: 
                </div>
                <div className="font-bold text-2xl pl-3">{domainData.domainName}</div>
              </div>
            </div>
            <div className="mt-14 ">
              <div className="font-bold text-2xl">Overview:</div>
              <br />
              <input
                className={`rounded-md bg-inputBg  text-[#3D4E74] w-full  p-5 pl-5 pt-5 text-lg custom-scrollbar ${overviewEditable ? "border border-textBlue" : ""
                  }`}
                onClick={() => setOverviewEditable(true)}
                onBlur={() => setOverviewEditable(false)}
                value={updatedData.overview?.about}
                onChange={(e) => handleOverviewChange('about', e.target.value)}
                contentEditable={overviewEditable}
                suppressContentEditableWarning={true}
              >
                {/* {domainData.overview?.about} */}
              </input>
            </div>
            <div className="mt-14 ">
              <div className="font-bold text-2xl">Features:</div>
              <br />
              <textarea
                className={` w-full rounded-md bg-inputBg text-[#3D4E74] p-5 pl-5 pt-5 text-lg custom-scrollbar ${featuresEditable ? "border border-textBlue" : ""}`}
                value={(updatedData.overview?.keyFeatures || []).join('\n')}
                onChange={(e) => {
                  const updatedFeatures = e.target.value.split('\n');
                  handleOverviewChange('keyFeatures', updatedFeatures);
                }}
                onFocus={() => setFeaturesEditable(true)}
                onBlur={() => setFeaturesEditable(false)}
                rows={5} // Adjust the number of rows as needed
              />
            </div>
            <div className="font-bold text-2xl mt-14 ">Syllabus</div>
            <EditSyllabus syllabus={domainData.syllabus} onDataUpdate={handleDataUpdate} />
            <div className="">
              <div className="flex justify-between mt-12 ">
                <div className="">
                  <div className="font-bold  text-xl">Cheat Sheet:</div>
                  <br />
                  <div
                    className={`min-w-96 h-11 rounded-md bg-inputBg pt-2 pl-5 text-lg ${cheatSheetEditable ? "border border-textBlue" : ""
                      }`}
                    onClick={() => setCheatSheetEditable(true)}
                    onBlur={() => setCheatSheetEditable(false)}
                    contentEditable={cheatSheetEditable}
                    suppressContentEditableWarning={true}
                  >
                    {cheatSheetContent}
                  </div>
                </div>
                <EditableTags title="Prerequisites" data={domainData.overview?.prerequisites} addTag={(newTags) => handleOverviewChange('prerequisites', newTags)}
                  deleteTag={(updatedTags) => handleInputChange('prerequisites', updatedTags)} />
              </div>
              <div className="mt-12 flex justify-between">
                <div>
                  <Difficulty difficultyLevel={domainData.difficultyLevel} diffLevel={(e) => { handleInputChange('difficultyLevel', e) }} />
                </div>
                <div className="flex flex-col justify-around">
                  <EditableTags title="Tags" data={domainData.tags} addTag={(newTags) => handleInputChange('tags', newTags)}
                    deleteTag={(updatedTags) => handleInputChange('tags', updatedTags)} />
                  <div className="">
                    <div className="font-bold  text-xl">
                      Time to complete:
                    </div>
                    <br />
                    <input
                      className={`w-96 pt-2 h-11 rounded-md bg-inputBg hover:border-2 hover:border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 text-xl ${timeToCompleteEditable ? "border border-textBlue" : ""
                        }`}
                      onClick={() => setTimeToCompleteEditable(true)}
                      onBlur={() => setTimeToCompleteEditable(false)}
                      contentEditable={timeToCompleteEditable}
                      suppressContentEditableWarning={true}
                      value={updatedData.timeToComplete}
                      onChange={(e) => { handleInputChange('timeToComplete', e.target.value) }}
                    >
                      {/* {domainData.timeToComplete} */}
                    </input>
                  </div>
                </div>
              </div>
              <div className="flex flex-col  mt-12 ">
                <div className="font-bold text-[24px] ">Logo:</div>
                <input
                  className={`w-96 pt-2 h-11 rounded-md bg-inputBg hover:border-2 hover:border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 text-xl ${timeToCompleteEditable ? "border border-textBlue" : ""
                    }`}
                  onClick={() => setTimeToCompleteEditable(true)}
                  onBlur={() => setTimeToCompleteEditable(false)}
                  contentEditable={timeToCompleteEditable}
                  suppressContentEditableWarning={true}
                  value={updatedData.logo}
                  onChange={(e) => { handleInputChange('logo', e.target.value) }}
                ></input>
              </div>
            </div>
            <div className="flex justify-end p-5">
              <div
                className="flex items-center text-xl font-bold bg-[#7EE46D] w-fit pl-2 p-1 pr-3 border-2 border-textBlue hover:shadow-xl rounded"

              >
                <button type="submit">
                  <FaPlus />
                </button>
                <button className="pl-1" onClick={(e) => {handleSubmit(e); handleLoading();}}>  Update Domain</button>
              </div>
            </div>
          </form>
        </div>
        <div className="border border-slate-400 w-5/6 m-auto mt-6 "></div>
        <div className="font-bold text-xl mt-8 pb-16 text-center">
           {domainData.createdAt ===   domainData.updatedAt ? "Created on: " +  checkAMorPM(domainData.createdAt) + " | " + dateConverter(domainData.createdAt) : "Updated on: " + checkAMorPM(domainData.updatedAt) + " | " + dateConverter(domainData.updatedAt) }
        </div>
      </div>
    </>
  );
};

export default EditDomain;

