import React, { useState } from "react";

const DynamicListInput = ({featureData}) => {
  const [points, setPoints] = useState([]);
  const [newPoint, setNewPoint] = useState("");

  const handleInputChange = (e) => {
    setNewPoint(e.target.value);
    // featureData(newPoint)
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter" && newPoint.trim() !== "") {
      setPoints([...points, newPoint.trim()]);
      featureData([...points, newPoint.trim()])
      setNewPoint("");
    }
  };

  const handleBackspace = () => {
    if (newPoint === "" && points.length > 0) {
      const updatedPoints = [...points];
      updatedPoints.pop();
      setPoints(updatedPoints);
    }
  };

  return (
    <div className="mt-8">
      <h2 className="font-bold text-2xl">Features:</h2>

     <div className=" w-full h-44 mt-2 rounded-md bg-inputBg hover:border-2 border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 pt-5 text-sm overflow-auto"    >
      <div className="textarea-container">
        <ul className="points-list">
          {points.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>

        <textarea
        className="w-full bg-inputBg focus:border focus:border-inputBg border-transparent resize-none "
          placeholder="Write your features here..."
          value={newPoint}
          onChange={handleInputChange}
          onKeyDown={handleEnterKey}
          onKeyUp={(e) => e.key === "Backspace" && handleBackspace()}
        />
      </div>
      </div>
    </div>
  );
};

export default DynamicListInput;