import React, { useEffect } from "react";
import { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { FaCircleNotch } from "react-icons/fa6";
import TestCase from "./components/TestCase";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import LanguageId from "./LanguageId"
import { useToast } from "@chakra-ui/react";
import Editor from "./components/Editor"
import InitialCode from "./components/InitialCode";
export default function Addproblem() {

  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    parentDomainId: '',
    properties: { subdomain: '', difficultyLevel: 'beginner', pointsToBeEarned: '3' },
    problemId: '',
    problemName: '',
    problemHeading: '',
    description: '',
    constraints: '',
    sampleInput: '',
    sampleOutput: '',
    langId: '',
    predefinedCode:{} ,
    // { "python3": "# Python initial code", "java": "// Java initial code", "cpp": "C++ initial code", "c": "C  initial code", "nodejs": "JS initial code", "ruby": "Ruby initial code" },
    // isLocked:false,`




    testCases: [{ testId: '', defaultInput: '', expectedOutput: '' }], // Initial value for difficulty
  });
  const [domains, setDomains] = useState([])
  const [syllabus, setSyllabus] = useState('')
  const [syllabusData, setSyllabusData] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast()
  const Base_Url=process.env.REACT_APP_LMS_URL

  async function fetchDomain() {
    try {
      const response = await axios.get(`${Base_Url}/alldomains`);


      setDomains(response.data.domains)

    }
    catch (error) {
      console.error("error fetching domain")

    }

  }


  async function fetchSyllabus(syllabus) {
    try {
      const response = await axios.get(`${Base_Url}/${syllabus}/syllabus`);

      // console.log(response.data)
      setSyllabusData(response.data.syllabus)

    }
    catch (error) {
      console.error("error fetching syllabus")

    }
  }


  // console.log(domains)

  useEffect(() => {
    fetchDomain()
    fetchSyllabus()
  }, [])






  const handleTextarea = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleTextEditor = (name, value) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  }



  const handleDropdown = (e) => {
    // const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      parentDomainId: e.target.value,
    }))
    setSyllabus(prevData => ({
      ...prevData,
      parentDomainId: e.target.value,
    }))
  }
  // console.log(syllabus);
  const langDropdown = (e) => {
    // const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      langId: e.target.value,
    }))
    setSyllabus(prevData => ({
      ...prevData,
      langId: e.target.value,
    }))
  }

  // const handleCodeChange = (lang, code) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     predefinedCode: prevData.predefinedCode.map((codeObj) =>
  //       codeObj.lang === lang ? { ...codeObj, InitialCode: code } : codeObj
  //     ),
  //   }));
  // };

  const handleCodeChange = (lang, code) => {
    setFormData((prevData) => ({
      ...prevData,
      predefinedCode: {
        ...prevData.predefinedCode,
        [lang]: code,
      },
    }));
  };



  const difficultyValues = {
    beginner: 3,
    intermediate: 5,
    advanced: 8,
    master: 12,
  };

  const handlePropertyClick = (property, value) => {
    // console.log(`${value}: ${difficultyValues[value]}`);

    const pointsToBeEarned = difficultyValues[value]?.toString() ?? '3';

    setFormData((prevData) => ({
      ...prevData,
      properties: {
        ...prevData.properties,
        [property]: value,
        pointsToBeEarned,
      },
    }));
  };





  const handleTestCase = (testCase) => {
    setFormData((prevData) => ({
      ...prevData,
      testCases: testCase,
    }));
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
    const domain = formData.parentDomainId;

    // console.log(formData);

    try {
      const response = await axios.post(`${Base_Url}/${domain}/addproblem`, {
        problem: formData,

        topicName: formData.properties.subdomain,

      }
      );
      console.log(response.data)
      toast({
        title: "Problem Added Successfully",
        status: "success",
        position: "top",
        isClosable: true,
      });
      // console.log("Success:", response.data);
      // console.log(formData)
      navigate("/")
    } catch (error) {
      console.error("Error Posting Problem:", error);
      toast({
        title: "Error Adding Problem",
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const handleLoading = () => {

    if (!formData.parentDomainId || !formData.properties.subdomain || !formData.problemName || !formData.description) {
      alert("fill all the fields");
      return;
    }

    // Proceed with loading if all required fields are filled
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Show loading div for 2-3 seconds
  };

  return (
    <div className="h-full pl-16 pr-16 text-lg bg-bgBlue w text-textBlue font-[raleway]">
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-[rgba(255,255,255,0.5)] backdrop-blur-sm">
          <div className="flex flex-col items-center justify-center font-extrabold text-6xl">
            <div className="animate-spin">
              <FaCircleNotch />
            </div>
            <div className="m-10">
              LOADING<span className="smooth-loading">.....</span>
            </div>
          </div>
        </div>
      )}
      <div className="pt-8 text-3xl font-bold text-center">Add Problem</div>
      <form onSubmit={handleSubmit}>
        <div className="flex justify-between m-7">
          <div>
            <div className="mt-10">
              <div className="">
                <select
                  name="parentDomainId"
                  value={formData.parentDomainId}
                  onChange={(e) => { handleDropdown(e); fetchSyllabus(e.target.value) }}
                  // onClick={fetchSyllabus(formData.parentDomainId)}

                  className="py-3 bg-blue-200 rounded h-11 w-80"
                >
                  <option className="py-2 bg-blue-100" value="">Domain </option>

                  {Array.isArray(domains) && domains.map((domain, index) => (
                    <option className="py-2 bg-blue-100" key={index} value={domain.domainId}>
                      <div className="flex justify-center " >
                        <div >{domain.domainName}</div> ||
                        <div> ID =  {domain.domainId}</div>  </div>
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="mt-16 ">
              <div className="">
                <select
                  name="subdomain"
                  value={formData.properties.subdomain}
                  onChange={(e) => { handlePropertyClick('subdomain', e.target.value) }}
                  className="bg-blue-200 rounded text-start h-11 w-80"

                >
                  <option className="py-5 bg-blue-100 " value="">Topic</option>
                  {Array.isArray(syllabusData) && syllabusData.map((syllabus, index) => (

                    <option className="py-2 bg-blue-100" value={syllabus.topicName} key={index}>
                      <div >
                        {syllabus.topicName}
                      </div>
                    </option>
                  ))}

                </select>
              </div>
            </div>
          </div>
          <div>
            <div className="m-6">
              <div className="mt-9">
                <div> Problem Name </div>
                <input
                  name="problemName"
                  value={formData.problemName}
                  onChange={handleTextarea}

                  className="min-w-96 pl-5 text-sm rounded-md outline-none resize-none h-11 bg-inputBg border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80"
                  placeholder="Enter Problem Name..."
                ></input>
              </div>
              <div className="mt-10">
                <div className="">
                  <select
                    name="langId"
                    value={formData.langId}
                    onChange={(e) => { langDropdown(e) }}
                    // onClick={fetchSyllabus(formData.parentDomainId)}

                    className="py-3 bg-blue-200 rounded h-11 w-96"
                  >
                    <option className="py-2 bg-blue-100" value="">Language ID </option>

                    {Array.isArray(LanguageId) && LanguageId.map((language, index) => (
                      <option className="py-2 bg-blue-100 space-x-5" key={index} value={language.langId}>
                        <div className="flex justify-cente " >
                          <div >{language.langId}</div> ||
                          <div> v{language?.versionIndex}</div>  </div>
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

          </div>

        </div>



        <div>
          <div className="">
            <div className="text-2xl font-bold">Heading:</div>

            <div>
              <textarea
                name="problemHeading"
                value={formData.problemHeading}
                onChange={handleTextarea}
                className="rounded-md resize-none bg-inputBg outline-none w-full text-[#3D4E74]  p-5 pl-5 pt-5 text-lg custom-scrollbar"
                placeholder="Enter heading here..."
              ></textarea>
            </div>
          </div>
          <div className="mt-14 ">
            <div className="text-2xl font-bold">Description :</div>
            <div>
              {/* <textarea
                name="description"
                value={formData.description}
                onChange={handleTextarea}
                className="rounded-md h-40 outline-none resize-none bg-inputBg w-full text-[#3D4E74]  p-5 pl-5 pt-5 text-lg custom-scrollbar"
                placeholder="Enter your Description here... "
              ></textarea> */}
              <Editor name="description" value={formData.description} onChange={(value) => handleTextEditor("description", value)} />
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-5">
          <div>
            <div className="mt-4 text-2xl font-semibold" htmlFor="">
              Difficulty
            </div>
            <div className="px-16 py-5 text-lg rounded-md w-72 min-w-96 h-117 bg-inputBg">
              <div>
                <div
                  onClick={() => handlePropertyClick('difficultyLevel', 'beginner')}
                  className={`w-5/6 bg-bgBlue p-2 rounded font-bold text-center hover:border border-blue-600 text-lg mb-4 mr-1 ${formData.properties.difficultyLevel === 'beginner' ? 'selected' : ''
                    }`}
                  style={{
                    boxShadow: formData.difficulty === 'beginner' ? '0 0 10px rgba(0, 0, 0, 0.3)' : 'none',
                    backgroundColor: formData.properties.difficultyLevel === 'beginner' ? '#699EDB' : '#F2F3FF',
                  }}
                >
                  Beginner{" "}
                </div>
                <div
                  onClick={() => handlePropertyClick('difficultyLevel', 'intermediate')}
                  className={`w-5/6 bg-bgBlue p-2 rounded font-bold text-center hover:border border-blue-600 text-lg mb-4 mr-1 ${formData.properties.difficultyLevel === 'intermediate' ? 'selected' : ''
                    }`}
                  style={{
                    boxShadow: formData.difficulty === 'intermediate' ? '0 0 10px rgba(0, 0, 0, 0.3)' : 'none',
                    backgroundColor: formData.properties.difficultyLevel === 'intermediate' ? '#699EDB' : '#F2F3FF',
                  }}
                >
                  Intermediate
                </div>
                <div
                  onClick={() => handlePropertyClick('difficultyLevel', 'advanced')}
                  className={`w-5/6 bg-bgBlue p-2 rounded font-bold text-center hover:border border-blue-600 text-lg mb-4 mr-1 ${formData.properties.difficultyLevel === 'advanced' ? 'selected' : ''
                    }`}
                  style={{
                    boxShadow: formData.difficulty === 'advanced' ? '0 0 10px rgba(0, 0, 0, 0.3)' : 'none',
                    backgroundColor: formData.properties.difficultyLevel === 'advanced' ? '#699EDB' : '#F2F3FF',
                  }}
                >
                  Advanced
                </div>
                <div
                  onClick={() => handlePropertyClick('difficultyLevel', 'master')}
                  className={`w-5/6 bg-bgBlue p-2 rounded font-bold text-center hover:border border-blue-600 text-lg mb-4 mr-1 ${formData.properties.difficultyLevel === 'master' ? 'selected' : ''
                    }`}
                  style={{
                    boxShadow: formData.difficulty === 'master' ? '0 0 10px rgba(0, 0, 0, 0.3)' : 'none',
                    backgroundColor: formData.properties.difficultyLevel === 'master' ? '#699EDB' : '#F2F3FF',
                  }}
                >
                  Master
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="mt-4 text-2xl font-semibold">Initial Code </div>
            {formData?.langId === "DSA" ? <InitialCode name="predefinedCode" predefinedCode={formData.predefinedCode} handleCodeChange={handleCodeChange} placeholder="Enter formatted code here..." /> 
            :  <textarea
              name="predefinedCode"
              value={formData.predefinedCode}
              onChange={handleTextarea}
              className="rounded-md outline-none resize-none bg-inputBg w-full text-[#3D4E74]  p-5 pl-5 pt-5 text-lg custom-scrollbar"
              placeholder="Enter your Description here... "
              rows={10}
              cols={90}
            ></textarea>}

            {/* </InitialCode> */}
          </div>
        </div>


        <div>
          <TestCase testCaseData={handleTestCase} />
        </div>
        <div>
          <div className="mt-10 ">
            <div className="text-2xl font-bold">Constraints :</div>

            <div>
              <textarea
                name="constraints"
                value={formData.constraints}
                onChange={handleTextarea}
                className="rounded-md bg-inputBg  resize-none w-full text-[#3D4E74] outline-none p-5 pl-5 pt-5 text-lg h-32 custom-scrollbar"
                placeholder="Enter constraints  here..."
              ></textarea>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col justify-between mt-5">
              <div className="">
                <div className="text-xl font-bold">Sample Input :</div>
                <textarea
                  name="sampleInput"
                  value={formData.sampleInput}
                  onChange={handleTextarea}

                  placeholder="Sample input... "
                  className="h-20 pt-2 pl-5 text-lg rounded-md outline-none resize-none min-w-96 bg-inputBg">
                </textarea>
              </div>
            </div>
            <div className="flex flex-col justify-between mt-5">
              <div className="flex flex-col justify-around">
                <div className="">
                  <div className="text-xl font-bold"> Sample Output :</div>

                  <textarea
                    name="sampleOutput"
                    value={formData.sampleOutput}
                    onChange={handleTextarea}
                    placeholder="Sample output..."
                    className="h-20 pt-2 pl-5 text-lg  rounded-md outline-none resize-none min-w-96 h-117 bg-inputBg">

                  </textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="w-5/6 m-auto mt-6 border border-slate-400 "></div>
          <div className="flex justify-end p-5 mt-3" >
            {/* <Link to={"/"}> */}
            <div className="flex items-center text-xl font-bold bg-[#7EE46D] w-fit pl-2 p-1 pr-3 border-2 border-textBlue hover:shadow-xl rounded mr-6">
              <div>
                <FaPlus />
              </div>
              <button type="submit" onClick={handleLoading} className="pl-2">Add Problem  </button>
            </div>
            {/* </Link> */}
          </div>

        </div>
        {/* <div className="pb-16 mt-8 text-xl font-bold text-center">
          Created on: 12:05 AM | 11-25-2023
        </div> */}
      </form>
    </div>
  );
}