import logo from './logo.svg';
import './App.css';
import AdminPanel from './Admin/AdminPanel';
import { Route, Routes } from 'react-router-dom';
import ViewDomain from './Admin/DomainPages/ViewDomain';
import EditDomain from './Admin/DomainPages/EditDomain';
import AddDomain from './Admin/DomainPages/AddDomain';
import ViewProblem from './Admin/ProblemPages/ViewProblem';
import Addproblem from './Admin/ProblemPages/AddProblem';
import EditProblem from './Admin/ProblemPages/EditProblem';

function App() {
  return (
    <div className="App m-6 p-5 ">
      <Routes>
      <Route path="/" exact element={<AdminPanel/>} />
      {/* <Route path="/" exact element={<AddDomain/>} /> */}
      <Route path="/add-domain" element={<AddDomain/>} />
      <Route path="/view-domain/:domain" element={<ViewDomain/>} />
      <Route path="/edit-domain/:domain" element={<EditDomain/>} />
      <Route path="/view-problem/:problem" element={<ViewProblem/>} />
      <Route path="/add-problem" element={<Addproblem/>} />
      <Route path="/edit-problem/:problem" element={<EditProblem/>} />
    </Routes>
     {/* <AdminPanel /> */}
    </div>
  );
}

export default App; 
