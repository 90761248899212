// import React, { useState } from 'react';
// import { FaPlus } from 'react-icons/fa';

// const TestCase = ({testCaseData}) => {
//   const [topics, setTopics] = useState([]);
//   const [testId, setTestId] = useState('');
//   const [defaultInput, setDefaultInput] = useState('');
//   const [expectedOutput, setExpectedOutput] = useState('');

//   const handleAddSyllabus = () => {
//     const newTopic = {
//       testId: testId || '',
//       defaultInput: defaultInput || '',
//       expectedOutput: expectedOutput || '',
//     };

//     setTopics([...topics, newTopic]);
//     testCaseData([...topics, newTopic])
//     // Clear input fields
//     setTestId('');
//     setDefaultInput('');
//     setExpectedOutput('');
// };



//   return (
//     <div className='bg-inputBg rounded'>
//       <div className="mt-14 w-full h-full rounded-md bg-inputBg pl-5 pt-5 text-sm">
//         {/* Topic input section */}
//         <div className="pt-7 pl-6">
//           <label className="font-bold pr-4 text-lg" htmlFor="id">
//             Test Case ID:
//           </label>
//           <input
//             type="text"
//             id="testId"
//             value={testId}
//             onChange={(e) => setTestId(e.target.value)}
//             placeholder="Enter Topic name..."
//             className="w-80 h-11 rounded-md bg-bgBlue hover:border-2 border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 text-sm"
//           />
//         </div>
//         {/* input input section */}
//         <div className="pt-7 pl-6">
//           <label className="font-bold pr-4 text-lg" htmlFor="input">
//             Input:
//           </label>
//           <br />
//           <textarea
//             cols="173"
//             rows="7"
//             id="defaultInput"
//             value={defaultInput}
//             onChange={(e) => setDefaultInput(e.target.value)}
//             placeholder="Enter input..."
//             className="rounded-md bg-bgBlue hover:border-2 border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 pt-5 text-sm"
//           ></textarea>
//         </div>
//         {/* Sample Code input section */}
//         <div className="pt-7 pl-6">
//           <label className="font-bold pr-4 text-lg" htmlFor="output">
//             Output:
//           </label>
//           <br />
//           <textarea
//             cols="173"
//             rows="7"
//             id="expectedOutput"
//             value={expectedOutput}
//             onChange={(e) => setExpectedOutput(e.target.value)}
//             placeholder="Enter Sample Code..."
//             className="rounded-md bg-bgBlue hover:border-2 border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 pt-5 text-sm"
//           ></textarea>
//         </div>
//         {/* Add Syllabus button */}
//         <div className="flex justify-end p-5">
//           <div
//             className="flex items-center text-xl font-bold bg-[#7EE46D] w-fit pl-2 p-1 pr-3 border-2 border-textBlue hover:shadow-xl rounded"
//             onClick={handleAddSyllabus}
//           >
//             <div>
//               <FaPlus />
//             </div>
//             <div className="pl-1"> Add Syllabus</div>
//           </div>
//         </div>
//       </div>

//       {/* Separator */}
//       <div className="border border-slate-400 w-5/6 m-auto mt-3"></div>

//       {/* Syllabus display section */}
//       <div className="font-bold bg-inputBg pr-4 pt-7 pl-6 text-lg">Syllabus:</div>
//       <div className="flex flex-wrap justify-between mt-6 pl-6 pr-6">
//         {topics.map((topic,index) => (
//           <div
//             key={topic.index}
//             className="w-1/4 bg-bgBlue p-2 rounded font-bold text-center text-lg mb-4 mr-1"
//           >
//             {topic.testId}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default TestCase;

import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
// import { v4 as uuidv4 } from 'uuid';
import { MdDelete } from "react-icons/md";

const TestCase = ({ testCaseData }) => {
  const [testCases, setTestCases] = useState([]);
  const [selectedTestCase, setSelectedTestCase] = useState(null);

  const handleAddTestCase = () => {
    // const testId = document.getElementById('TestId').value.trim();
    const testId = testCases.length + 1;
    const defaultInput = document.getElementById('defaultInput').value.trim();
    const expectedOutput = document.getElementById('expectedOutput').value.trim();

    // Check if any of the fields are empty
    if (!testId || !defaultInput || !expectedOutput) {
      alert('Please fill out all fields.');
      return; // Exit function if any field is empty
    }

    if (selectedTestCase) {
      // Update existing test case
      const updatedTestCases = testCases.map(testCase => {
        if (testCase.id === selectedTestCase.id) {
          return {
            ...testCase,
            // testId: testId || `TestCase ${testCases.length + 1}`,
            testId: testId,
            defaultInput: defaultInput || "",
            expectedOutput: expectedOutput || "",
          };
        }
        return testCase;
      });
      setTestCases(updatedTestCases);
      testCaseData(updatedTestCases);
      setSelectedTestCase(null)
    } else {
      // Add new test case
      const newTestCase = {
        // id: (testCases.length + 1).toString(),
        // testId: testId || `TestCase ${testCases.length + 1}`,
        testId: testCases.length + 1,
        defaultInput: defaultInput || "",
        expectedOutput: expectedOutput || "",
      };

      const updatedTestCases = [...testCases, newTestCase];
      setTestCases(updatedTestCases);
      testCaseData(updatedTestCases);
    }

    // Clear input fields
    // document.getElementById('TestId').value = '';
    document.getElementById('defaultInput').value = '';
    document.getElementById('expectedOutput').value = '';
  };

  const handleTestCaseClick = (testCase) => {
    setSelectedTestCase(testCase);
    // Set values in input fields
    // document.getElementById('TestId').value = testCase.testId;
    document.getElementById('defaultInput').value = testCase.defaultInput;
    document.getElementById('expectedOutput').value = testCase.expectedOutput;
  };

  const handleDelete = (testId) => {
    const updatedTestCases = testCases.filter(testCase => testCase.testId !== testId);
    setTestCases(updatedTestCases);
    testCaseData(updatedTestCases);
  };


  return (
    <div className='bg-inputBg rounded'>
      <div className="mt-14 w-full h-full rounded-md bg-inputBg pl-5 pt-5 text-sm">
        <p className='text-center font-semibold text-[30px]'>TEST CASES</p>
        {/* Test case input section */}
        {/* <div className="pt-7 pl-6">
          <label className="font-bold pr-4 text-lg" htmlFor="TestId">
            Test Case ID:
          </label>
          <input
            type="text"
            id="TestId"
            placeholder="Enter Test Case ID..."
            className="w-80 h-11 rounded-md bg-bgBlue hover:border-2 border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 text-sm"
          />
        </div> */}
        {/* Input section */}
        <div className="pt-7 pl-6">
          <label className="font-bold pr-4 text-lg" htmlFor="input">
            Input:
          </label>
          <br />
          <textarea
            cols="173"
            rows="7"
            id="defaultInput"
            placeholder="Enter Input..."
            className="rounded-md bg-bgBlue hover:border-2 border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 pt-5 text-sm"
          ></textarea>
        </div>
        {/* Output section */}
        <div className="pt-7 pl-6">
          <label className="font-bold pr-4 text-lg" htmlFor="output">
            Output:
          </label>
          <br />
          <textarea
            cols="173"
            rows="7"
            id="expectedOutput"
            placeholder="Enter Output..."
            className="rounded-md bg-bgBlue hover:border-2 border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 pt-5 text-sm"
          ></textarea>
        </div>
        {/* Add Test Case button */}
        <div className="flex justify-end p-5">
          <div
            className="flex items-center text-xl font-bold bg-[#7EE46D] w-fit pl-2 p-1 pr-3 border-2 border-textBlue hover:shadow-xl rounded"
            onClick={handleAddTestCase}
          >
            <div>
              <FaPlus />
            </div>
            <div className="pl-1"> Add Test Case</div>
          </div>
        </div>
      </div>

      {/* Separator */}
      <div className="border border-slate-400 w-5/6 m-auto mt-3"></div>

      {/* Test Cases display section */}
      <div className="font-bold bg-inputBg pr-4 pt-7 pl-6 text-lg">Test Cases:</div>
      <div className="flex flex-wrap justify-between mt-6 pl-6 pr-6">
        {testCases.map((testCase) => (
          <div
            key={testCase.id}
            className="w-1/4 bg-bgBlue p-2 flex place-content-evenly rounded font-bold text-center text-lg mb-4 mr-1 cursor-pointer"
          // Handle click event
          >

            <button className='w-3/4' onClick={() => handleTestCaseClick(testCase)}>{testCase.testId}</button>
            <button onClick={() => handleDelete(testCase.testId)}>
              <MdDelete className='w-11' />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestCase;
