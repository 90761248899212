import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { FaPen } from "react-icons/fa6";
import ViewSyllabus from "./components/ViewSyllabus";
import axios from "axios";
import { useToast } from "@chakra-ui/react";

const ViewDomain = () => {
  // const navigate = useNavigate();
  // const handleClick = () => {
  //   navigate('/edit')

  // }

  const [domainData, setDomainData] = useState({
    domainId: '',
    domainName: '',
    totalProblems: 0,
    tags: [],
    logo: '',
    introductoryCode: '',
    overview: {
      about: '', keyFeatures: [],
      prerequisites: '',
    },
    difficultyLevel: 'beginner',
    timeToComplete: '',
    syllabus: [{ topicName: '', description: '', exampleCode: '' }],
    problems: [],
    logo: ''
  });

const toast=useToast()
const Base_Url=process.env.REACT_APP_LMS_URL
  const { domain } = useParams()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Base_Url}/${domain}/getdomain`);
        setDomainData(response.data.domain);
        // console.log(response.data.domain)
      } catch (error) {
        console.error('Error fetching previous data:', error);
        // Handle errors appropriately
        toast({
          title: "An Error Occoured",
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    };

    fetchData();
  }, [domain]);

  const navigate = useNavigate()


  function handleEdit() {
    navigate(`/edit-domain/${domain}`)
  }

  async function handleDelete() {
    const confirmation = prompt("Please type 'deletedomain' to confirm deletion:");
    if (confirmation === 'deletedomain') {
      try {
        await axios.delete(`${Base_Url}/${domain}/deletedomain`)
        toast({
          title: "Domain Deleted Successfully ",
          status: "success",
          position: "top",
          isClosable: true,
        });
        navigate("/")
      }
      catch (error) {
        console.log(error);
        toast({
          title: "Error Deleting Domain",
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Deletion Cancelled",
        status: "warning",
        position: "top",
        isClosable: true,
      });
    }
  }

  function dateConverter(date) {
    // const [year, month, day] = date.split('-');
    // const formattedDate = `${day}-${month}-${year}`;
    if(!date){
      return null
    }
    const apiDate = date.slice(0, 10);
    const [year, month, day] = apiDate.split('-')
    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;

  }

  function checkAMorPM(timeString) {
    if(!timeString){
      return null;
    }
    const hour = parseInt(timeString.substring(11, 13), 10);
    if (hour >= 12) {
        return timeString.slice(11,16) + " " + "PM";
    } else {
        return timeString.slice(11,16) + " " + "AM";
    }
}



  return (
    <>
      <div className="bg-bgBlue w text-textBlue text-lg h-full font-raleway pr-16 pl-16 font-semibold font-[raleway]">
        <div className="font-bold text-center text-3xl pt-8">VIEW DOMAIN</div>
        <div className="flex justify-end p-5 ">
          <link rel="import" href="component.html" />
          <button className="flex items-center text-xl font-bold bg-[#7EE46D] w-fit pl-2 p-1 pr-3 border-2 border-textBlue hover:shadow-xl rounded mr-6">
            <div>
              <FaPen />
            </div>
            <div className="pl-2" onClick={handleEdit}> Edit</div>
          </button>
          <button className="flex items-center text-xl font-bold bg-[#E04E4E] w-fit pl-2 p-1 pr-3 border-2 border-textBlue hover:shadow-xl rounded" onClick={handleDelete}>
            <div>
              <MdDelete />
            </div>
            <div className="pl-1"> Delete</div>
          </button>
        </div>
        <div>
          <form action="submit">
            <div className="flex justify-between ">
              <div className="flex">
                <div className="font-bold  text-2xl" htmlFor="domainId">
                  Domain ID:
                </div>
                <div className="font-bold text-2xl pl-3">{domainData.domainId}</div>
              </div>
              <div className="flex">
                <div className="font-bold  text-2xl" htmlFor="domainId">
                  Domain Name:
                </div>
                <div className="font-bold text-2xl pl-3">{domainData.domainName}</div>
              </div>
            </div>
            <div className="mt-14 ">
              <div className="font-bold  text-2xl">Overview:</div>
              <br />
              <div className="rounded-md bg-inputBg  text-[#3D4E74]  p-5 pl-5 pt-5 text-lg custom-scrollbar">
                {domainData.overview.about}
              </div>
            </div>
            <div className="mt-14 ">
              <div className="font-bold  text-2xl">Features:</div>
              <br />
              <div className="rounded-md bg-inputBg  text-[#3D4E74]  p-5 pl-5 pt-5 text-lg custom-scrollbar">
                <ul>
                  {Array.isArray(domainData.overview?.keyFeatures) && domainData.overview?.keyFeatures.map((feature, index) => (
                    <li key={index}>{feature} </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="font-bold text-2xl mt-14 ">Syllabus</div>
            <ViewSyllabus syllabus={Array.isArray(domainData?.syllabus) && domainData?.syllabus} />
            <div className="flex justify-between">
              <div className="flex flex-col justify-between mt-12 ">
                <div className="">
                  <div className="font-bold  ">Cheat Sheet:</div>
                  <br />
                  <div className="min-w-96 h-11 rounded-md bg-inputBg pt-2   pl-5 text-lg">
                    www.example.com//
                  </div>
                </div>
                <div>
                  <div className="font-bold text-xl">Difficulty</div>
                  <div className="w-96 bg-inputBg flex flex-col items-center p-5">
                    <div className="w-5/6 bg-bgBlue p-2 rounded font-bold text-center hover:border border-blue-600 text-lg mb-4 mr-1c">
                      {domainData.difficultyLevel}
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="font-bold  text-xl">Tags:</div>
                  <br />
                  <div className="min-w-96 h-11 rounded-md bg-inputBg pt-2   pl-5 text-lg   ">
                    {Array.isArray(domainData?.tags) && domainData?.tags.map((tag, index) => (

                      <span className="bg-bgBlue mr-1 " key={index}>{tag}</span>
                    ))}

                  </div>
                </div>
              </div>
              <div className="mt-12 rounded-md flex flex-col justify-between ">
                <div className="flex  flex-col justify-around bg-[#C5E0FF]">
                  <div className="">
                    <div className="font-bold  text-xl">Prerequisites:</div>
                    <br />
                    <div className="min-w-96 h-auto   pt-2   pl-5 text-lg">
                      {Array.isArray(domainData.overview?.prerequisites) && domainData.overview?.prerequisites.map((prereq, index) => (

                        <li className="bg-bgBlue pl-2 mr-2" key={index}>{prereq}</li>
                      ))}
                    </div>
                  </div>
                  <div className="flex m-6 bg-[#C5E0FF]">
                    <div className="font-bold  text-xl">Time to complete:</div>
                    <div className="font-bold pl-3  text-xl"> {domainData.timeToComplete} Hours</div>
                  </div>
                </div>
                <div className="font-bold  text-3xl">
                  Total no of problems: {Array.isArray(domainData?.problems) && domainData?.problems.length}
                </div>
              </div>
            </div>
            <div className="m-auto mt-12 w-1/2 flex justify-center ">
              <img src={domainData?.logo} alt="" width={80} />
              <div className="flex flex-col ml-6">
                <div className="font-bold text-[24px] ">Logo:</div>
                <div className="w-96 h-11 rounded-md bg-inputBg pt-2   pl-5 text-lg m-auto">
                  {domainData?.logo?.slice(0, 35)}...
                </div>
              </div>

            </div>
          </form>
        </div>
        <div className="border border-slate-400 w-5/6 m-auto mt-6 "></div>
        {domainData &&
          <div className="font-bold text-xl mt-8 pb-16 text-center">
           {domainData.createdAt ===   domainData.updatedAt ? "Created on: " +  checkAMorPM(domainData.createdAt) + " | " + dateConverter(domainData.createdAt) : "Updated on: " + checkAMorPM(domainData.updatedAt) + " | " + dateConverter(domainData.updatedAt) }
          </div>
        }

      </div>
    </>
  );
};

export default ViewDomain;

