import React, { useState } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';

const Syllabus = ({ syllabusData }) => {
  const [topics, setTopics] = useState([]);
  const [selectedTopicIndex, setSelectedTopicIndex] = useState(null);
  const [editable, setEditable] = useState(false);
  const [newTopic, setNewTopic] = useState({
    topicName: "",
    description: "",
    exampleCode: "",
  });

  const handleAddSyllabus = () => {
    const topicName = document.getElementById('TopicName').value.trim();
    const description = document.getElementById('desc').value.trim();
    const sampleCode = document.getElementById('samplecode').value.trim();

    // Check if any of the fields are empty
    if (!topicName || !description || !sampleCode) {
      alert('Please fill out all fields.');
      return; // Exit function if any field is empty
    }

    if (selectedTopicIndex !== null) {
      // Update existing topic
      const updatedTopics = topics.map((topic, index) => {
        if (index === selectedTopicIndex) {
          return {
            ...topic,
            topicName: topicName || `Topic ${topics.length + 1}`,
            description: description || "",
            exampleCode: sampleCode || "",
          };
        }
        return topic;
      });
      setTopics(updatedTopics);
      syllabusData(updatedTopics);
      setSelectedTopicIndex(null); // Clear selected topic after updating
    } else {
      // Add new topic
      const newTopic = {
        topicName: topicName || `Topic ${topics.length + 1}`,
        description: description || "",
        exampleCode: sampleCode || "",
      };

      const updatedTopics = [...topics, newTopic];
      setTopics(updatedTopics);
      syllabusData(updatedTopics);
    }

    // Clear input fields
    document.getElementById('TopicName').value = '';
    document.getElementById('desc').value = '';
    document.getElementById('samplecode').value = '';
  };

  const handleTopicClick = (topic, index) => {
    setSelectedTopicIndex(index);
    setEditable(true);
    // Set values in input fields
    document.getElementById('TopicName').value = topic.topicName;
    document.getElementById('desc').value = topic.description;
    document.getElementById('samplecode').value = topic.exampleCode;
  };

  const handleDeleteTopic = (index) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this topic?");
    if (confirmDelete) {
      const updatedTopics = topics.filter((_, i) => i !== index);
      setTopics(updatedTopics);
      syllabusData(updatedTopics);
      if (index === selectedTopicIndex) {
        setEditable(false);
        setSelectedTopicIndex(null);
        setNewTopic({
          topicName: "",
          description: "",
          exampleCode: "",
        });
      }
    }
  };

  return (
    <div className='bg-inputBg rounded'>
      <div className="mt-14 w-full h-full rounded-md bg-inputBg pl-5 pt-5 text-sm">
        {/* Topic input section */}
        <div className="pt-7 pl-6">
          <label className="font-bold pr-4 text-lg" htmlFor="TopicName">
            Topic name:
          </label>
          <input
            type="text"
            id="TopicName"
            placeholder="Enter Topic name..."
            className="w-80 h-11 rounded-md bg-bgBlue hover:border-2 border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 text-sm"
          />
        </div>
        {/* Description input section */}
        <div className="pt-7 pl-6">
          <label className="font-bold pr-4 text-lg" htmlFor="desc">
            Description:
          </label>
          <br />
          <textarea
            cols="173"
            rows="7"
            id="desc"
            placeholder="Enter Description..."
            className="rounded-md bg-bgBlue resize-none hover:border-2 border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 pt-5 text-sm"
          ></textarea>
        </div>
        {/* Sample Code input section */}
        <div className="pt-7 pl-6">
          <label className="font-bold pr-4 text-lg" htmlFor="samplecode">
            Sample Code:
          </label>
          <br />
          <textarea
            cols="173"
            rows="7"
            id="samplecode"
            placeholder="Enter Sample Code..."
            className="rounded-md bg-bgBlue hover:border-2 border-textBlue focus:placeholder:text-textBlue placeholder-opacity-80 pl-5 pt-5 text-sm resize-none"
          ></textarea>
        </div>
        {/* Add Syllabus button */}
        <div className="flex justify-end p-5">
          <div
            className="flex items-center text-xl font-bold bg-[#7EE46D] w-fit pl-2 p-1 pr-3 border-2 border-textBlue hover:shadow-xl rounded"
            onClick={handleAddSyllabus}
          >
            <div>
              <FaPlus />
            </div>
            <div className="pl-1"> Add Syllabus</div>
          </div>
        </div>
      </div>

      {/* Separator */}
      <div className="border border-slate-400 w-5/6 m-auto mt-3"></div>

      {/* Syllabus display section */}
      <div className="font-bold bg-inputBg pr-4 pt-7 pl-6 text-lg">Syllabus:</div>
      <div className="flex flex-wrap justify-between mt-6 pl-6 pr-6">
        {topics.map((topic, index) => (
          <div
            key={index}
            className={`bg-bgBlue w-1/4 p-2 rounded font-bold text-center text-lg mb-4 mr-1 cursor-pointer relative`}
            onClick={() => handleTopicClick(topic, index)} // Handle click event
          >
            {topic.topicName}
            <FaTrash
              className="absolute top-0 right-0 m-2 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation(); // Prevent click event from propagating to parent
                handleDeleteTopic(index);
              }}
            />
          </div>
        ))}
      </div>

      {/* Selected Topic Details */}
      {/* Your existing JSX code for selected topic details */}
    </div>
  );
};

export default Syllabus;
