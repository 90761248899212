
import React, { useEffect, useState } from 'react';

const ViewSyllabus = (props) => {
    const[visible,setVisible]= useState(false)
    const[data,setData]=useState({
        topicName:'',
        description:'',
        exampleCode:'',
        problems:[]
      })
  const [selectedTopic, setSelectedTopic] = useState();

  const handleTopicClick = (index) => {
    setSelectedTopic(index);
    setVisible(true)
  };

  useEffect(()=>{
    setData(props.syllabus)
    console.log(props.syllabus)
  },[props.syllabus])



  return (
    <div className="w-full h-full rounded-md bg-inputBg p-6">
      <div className="font-bold text-lg">Topics</div>
      <div className="flex flex-wrap justify-between mt-6">
        {props?.syllabus.map((topic, index) => (
          <div
            key={index}
            onClick={() => handleTopicClick(index)}
            className={`w-1/4 bg-${selectedTopic === index ? '[#699EDB]' : 'bgBlue'} p-2 rounded font-bold text-center text-lg mb-4 mr-1 cursor-pointer`}
          >
            {topic.topicName}
          </div>
        ))}
      </div>

      {visible && (
        <>
          <div className="border border-slate-400 w-5/6 m-auto mt-8 mb-8"></div>

          <div className="pt-7 pl-6 flex items-center">
            <div className="font-bold pr-4 text-lg">Topic name:</div>
            <div className="w-80 h-11 rounded-md bg-bgBlue text-xl pt-2 pl-5">
              {selectedTopic !== null ? data[selectedTopic]?.topicName : 'Select a topic'}
            </div>
          </div>

          <div className="pt-7 pl-6">
            <div className="font-bold pr-4 text-lg">Description:</div>
            <br />
            <div className="w-full pr-7 rounded-md bg-bgBlue pb-5 pl-5 pt-5 text-sm">
              {selectedTopic !== null ? data[selectedTopic]?.description : 'Select a topic to see the description'}
            </div>
          </div>

          <div className="pt-7 pl-6">
            <div className="font-bold pr-4 text-lg">Sample Code:</div>
            <br />
            <div className="rounded-md bg-bgBlue pb-5 pl-5 pt-5 text-sm">
              {selectedTopic !== null ? data[selectedTopic]?.exampleCode : 'Select a topic to see the sample code'}
            </div>
          </div>

          <div className="flex items-center pt-11 justify-center">
            <div className="font-bold text-2xl text-center">
              Total no of problems: {selectedTopic !== null ? data[selectedTopic]?.problems.length : 'Select a topic to see the sample code'}
            </div>
            {/* <div className="text-3xl ml-4">|</div>
            <div className="ml-4 underline hover:text-sky-800 hover:font-semibold">
              View problems
            </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default ViewSyllabus;
