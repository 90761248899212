import React, { useState, useEffect } from "react";
import languages from "../LanguageId";
import {useLocation} from 'react-router-dom'

function InitialCode({ predefinedCode, handleCodeChange, handleFullScreen, handleReset, ...props }) {
    const location=useLocation()
    const [language, setLanguage] = useState("");
    const [code, setCode] = useState("");
    const [editable,setEditable]=useState(true)

    const langDropdown = (e) => {
        const selectedLang = e.target.value;
        setLanguage(selectedLang);
        if (predefinedCode) {
            const initialCode = predefinedCode[selectedLang] || "";
            setCode(initialCode);
        }
    };

    useEffect(() => {
        if (predefinedCode) {
            const initialCode = predefinedCode[language] || "";
            setCode(initialCode);
        }
    }, [language, predefinedCode]);

    useEffect(()=>{
        if(location.pathname.includes('view-problem')){
            setEditable(false)
        }
    },[])

    const handleCodeChangeLocal = (e) => {
        setCode(e.target.value);
        handleCodeChange(language, e.target.value);
    };

    return (
        <div className="bg-inputBg space-y-2 place-content-between text-white text-[16px] p-3 rounded-[25px]">
            <div className="flex flex-row space-x-2">
                <div className="m-3 font-semibold text-black">Language:</div>
                <div className="rounded-[10px] p-2">
                    <select
                        name="langId"
                        value={language}
                        onChange={langDropdown}
                        className="bg-[#200068] outline-none p-2 rounded-md"
                    >
                        <option className="bg-[#0E4C9299]" value="">
                            Language
                        </option>
                        {Array.isArray(languages) &&
                            languages
                                .filter((language) => language.langId !== "DSA")
                                .map((language, index) => (
                                    <option
                                        className="bg-[#0E4C9299]"
                                        key={index}
                                        value={language.langId}
                                    >
                                        {language.langId}
                                    </option>
                                ))}
                    </select>
                </div>
            </div>
            <textarea
                name="predefinedCode"
                value={code}
                placeholder="Enter formatted code here"
                rows={8}
                cols={80}
                className="p-4 text-lg rounded-md bg-inputBg text-black overflow-y-auto resize-none"
                onChange={handleCodeChangeLocal}
                disabled={!editable}
            ></textarea>
        </div>
    );
}

export default InitialCode;
